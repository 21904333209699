import React,{ useEffect, useState } from "react"

function WinterstorageCalc({calcPrices, selectedUserForm}) {
  const [calculated, setCalculated] = useState({})

  function Calculate() {
    try {
      //formating
      selectedUserForm.form.boat_length= selectedUserForm.form.boat_length.replace(',', '.')
      selectedUserForm.form.boat_width  = selectedUserForm.form.boat_width.replace(',', '.')
      selectedUserForm.form.engine_hp  = selectedUserForm.form.engine_hp.split(',').join('.')
      selectedUserForm.form.engine_hp = selectedUserForm.form.engine_hp.replace(/[^0-9.]+/g, "");
      //end formating
      let newState      = {boatServicesPrice: 0, other: 0, discount: 0}
      let boatLength    = parseFloat(selectedUserForm.form.boat_length)
      let boatWidth     = parseFloat(selectedUserForm.form.boat_width)
      let boatStorageM2 = (boatLength+ 0.5)* (boatWidth+ 0.5)
      let boatRealM2    = (boatLength)* (boatWidth)
      let boatServices  =
        Object.entries(selectedUserForm.form)
          .filter (e => e[1]==='yes')
          .map    (e => e[0])
          .map    (s => calcPrices[0]
            .filter((e)=> e.name===s)
          )
          .map    (s => s[0])
          .filter (s => s!== undefined)
          .filter (s => s.price!=="0.00"&&s.price)
          .map    (s => s.price)
      boatServices.forEach(s => {
        const price = parseFloat(s.split('/')[0])
        const unit  = s.split('/')[1]
        if (unit==='m')   newState.boatServicesPrice+= price* boatLength
        if (unit==='m2')  newState.boatServicesPrice+= price* boatRealM2
      })
      newState.storage= 
        calcPrices[3]
          .map    (s => s.type=== selectedUserForm.form.winterstorage_type&& parseFloat(s.price))
          .filter (s => s!== undefined&&s!==false)
          .reduce (s => s)* boatStorageM2
      if (selectedUserForm.form.pickup_and_launch=== 'yes') {
        newState.pickup_and_launch= 
          calcPrices[1]
            .filter (s => s.value===selectedUserForm.form.pickup_location)
            .map    (s => s.price * 2)[0]
      } else newState.pickup_and_launch= 0
      if (selectedUserForm.form.engine_seasonal_maintenance=== 'yes') {
        newState.engine =
          calcPrices[2]
            .filter(s => s.engine_type=== selectedUserForm.form.engine_cycle)
            .filter(s => s.hp_from<= Math.floor(parseFloat(selectedUserForm.form.engine_hp)))
            .filter(s => s.hp_to>= Math.floor(parseFloat(selectedUserForm.form.engine_hp)))
            .map(s => parseFloat(s.price))
            .reduce(s => s)
      } else {
        newState.engine = 0
      }
      setCalculated(newState)
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(()=> {
    Calculate()
  }, [])

  return (
    <div>
      <div style={{display:"flex", justifyContent: "space-between", flexWrap: "wrap"}}>
        <div>
          Uppt./Sjös.
        </div>
        <div style={{display:"flex"}}>
          <input
              style={{width: "60px", textAlign: "right", outline: "none", border: "none", padding: "0px", margin: "0px", fontSize:"16px"}}
              value={calculated.pickup_and_launch!==undefined&&calculated.pickup_and_launch}
              onChange={(e)=> {!isNaN(e.target.value)&&(e.target.value)&&setCalculated(prevState => ({...prevState, pickup_and_launch: parseFloat(e.target.value)}))}}
            />
        </div>
      </div>
      <div style={{display:"flex", justifyContent: "space-between", flexWrap: "wrap"}}>
        <div>
          Tjänster
        </div>
        <div style={{display:"flex"}}>
          <input
              style={{width: "60px", textAlign: "right", outline: "none", border: "none", padding: "0px", margin: "0px", fontSize:"16px"}}
              value={calculated.boatServicesPrice!==undefined&&calculated.boatServicesPrice}
              onChange={(e)=> {!isNaN(e.target.value)&&(e.target.value)&&setCalculated(prevState => ({...prevState, boatServicesPrice: parseFloat(e.target.value)}))}}
            />
        </div>
      </div>
      <div style={{display:"flex", justifyContent: "space-between", flexWrap: "wrap"}}>
        <div>
          Förvaring
        </div>
        <div style={{display:"flex"}}>
          <input
              style={{width: "60px", textAlign: "right", outline: "none", border: "none", padding: "0px", margin: "0px", fontSize:"16px"}}
              value={calculated.storage!==undefined&&calculated.storage}
              onChange={(e)=> {!isNaN(e.target.value)&&(e.target.value)&&setCalculated(prevState => ({...prevState, storage: parseFloat(e.target.value)}))}}
            />
        </div>
      </div>
      <div style={{display:"flex", justifyContent: "space-between", flexWrap: "wrap"}}>
        <div>
          Motorservice
        </div>
        <div style={{display:"flex"}}>
        <input
            style={{width: "60px", textAlign: "right", outline: "none", border: "none", padding: "0px", margin: "0px", fontSize:"16px"}}
            value={calculated.engine!==undefined&&calculated.engine}
            onChange={(e)=> {!isNaN(e.target.value)&&(e.target.value)&&setCalculated(prevState => ({...prevState, engine: parseFloat(e.target.value)}))}}
          />
        </div>
      </div>
      <div style={{display:"flex", justifyContent: "space-between", flexWrap: "wrap"}}>
        <div>
          Annat
        </div>
        <div style={{display:"flex"}}>
          <input
            style={{width: "60px", textAlign: "right", outline: "none", border: "none", padding: "0px", margin: "0px", fontSize:"16px"}}
            value={calculated.other!==undefined&&calculated.other}
            onChange={(e)=> {!isNaN(e.target.value)&&(e.target.value)&&setCalculated(prevState => ({...prevState, other: parseFloat(e.target.value)}))}}
          />
        </div>
      </div>
      <div style={{display:"flex", justifyContent: "space-between", flexWrap: "wrap"}}>
        <div>
          Rabatt
        </div>
        <div style={{display:"flex"}}>
          <input 
            style={{width: "40px", textAlign: "right", outline: "none", border: "none", padding: "0px", margin: "0px", fontSize:"16px"}}
            value={calculated.discount!==undefined&&calculated.discount}
            onChange={(e)=> {!isNaN(e.target.value)&&setCalculated(prevState => ({...prevState, discount: e.target.value}))}}
            />%
        </div>
      </div>
      <div style={{display:"flex", borderBottom: "1px solid #000"}}></div>
      <div style={{display:"flex", justifyContent: "space-between", flexWrap: "wrap"}}>
        <div>
          Total
        </div>
        <div>
          {calculated&&(calculated.storage+ calculated.engine+ calculated.pickup_and_launch+ calculated.boatServicesPrice+ calculated.other).toFixed(2)}
        </div>
      </div>
      <div style={{display:"flex", justifyContent: "space-between", flexWrap: "wrap"}}>
        <div>
          Inkl. rabatt
        </div>
        <div>
          {calculated&&((calculated.storage+ calculated.engine+ calculated.pickup_and_launch+ calculated.boatServicesPrice+ calculated.other)*(1-(calculated.discount/100))).toFixed(2)}
        </div>
      </div>
      <br/>
      <div style={{display:"flex", justifyContent: "center"}}>
        <input type="button" value="AutoKalk." onClick={(e)=>{e.preventDefault();Calculate()}}/>
      </div>
    </div>
  )
}

export default WinterstorageCalc