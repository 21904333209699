import React, { useEffect } from 'react';
import { Switch, Route, useHistory, useLocation } from "react-router-dom"
import AccountChangePassword from './AccountChangePassword'
import AccountEditInformation from './AccountEditInformation'
import ProfilePicture from './ProfilePicture'
import './Account.css'

const Account = ((props) => {
  const {user} = props
  useEffect(()=> {
  }, [])
  const history = useHistory()
  const location= useLocation();
  return (
    <Switch>
      <Route path="/account/changepassword">
        <AccountChangePassword {...props}/>
      </Route>
      <Route path="/account/editinformation">
        {!user.firstlogin&&<AccountEditInformation {...props}/>}
      </Route>
      <Route path="/account/profilepicture">
        {user.privileges>1&&<ProfilePicture {...props}/>}
      </Route>
    </Switch>
  )
})

export default Account;