import React, { useState, useEffect, useRef, cloneElement, isValidElement, Children } from 'react'
import {useHistory} from "react-router-dom"
import './DropDown.css'

export default function Dropdown(props) {
  const { 
    menu, 
    format, 
    exclude,
    onchange, 
    type, 
    className, 
    selected, 
    disableposition, 
    disableOutsideClick, 
    disableClose 
  } = props

  const xRef= useRef()
  const yRef= useRef()
  const openRef = useRef(false)

  function Outside(ref){
    useEffect(()=>{
      function handleClickOutside(e) {
        if (ref.current && !ref.current.contains(e.target) && openRef.current) {
          setOpen(false)
          openRef.current= false
        } else if (!openRef.current){
          xRef.current  = e.clientX
          yRef.current  = e.clientY
        }  
      }
      document.addEventListener("mousedown", handleClickOutside)
      return () => {
        document.removeEventListener("mousedown", handleClickOutside)
        openRef.current= false
      }  
    }, [ref])
  }

  const wrapperRef = useRef(null)
  if (!disableOutsideClick || !disableClose) Outside(wrapperRef)
  const [open, setOpen] = useState(false)
  const toggle = () => {
    if (!disableClose|| !open) {
      setOpen(!open) 
      openRef.current= !openRef.current
    } 
  }
  let history= useHistory()
  // <div id="DropDown" ref={wrapperRef} className={className?className+" relative":"dd-wrapper"+" cursorpointer relative"}>
  //const ElementWrapper = Children.map(props.children, child => { if (isValidElement(child)) return cloneElement(child, {onClick: toggle, ref:wrapperRef, id:"DropDown", className:className})})
  return (
    <div id="DropDown" ref={wrapperRef} className={className?className+" relative":"dd-wrapper"+" cursorpointer relative"}>
      {Children.map(props.children, child => { if (isValidElement(child)) return cloneElement(child, {onClick: toggle})})}
      {open&&
        <ul className="dd-list" style={!disableposition?{position: "fixed", left: xRef.current+"px", top: yRef.current+"px"}:{position: "relative"}}>
          {menu.map((item, i) => {if (item.field!== exclude) return ( // looped menu array
            <li className="dd-list-item" key={i} > 
              {type==="checkbox"&&
                <>
                  <input id={item.field} type="checkbox" checked={item.toggled} onChange={()=>onchange(i, selected, setOpen)}/>
                  <label htmlFor={item.field}>
                    {format?format(item.field):item.field}
                  </label>
                </>}
              {type==="list"&& // "selected" returns object from parent
                <div onClick={()=>onchange(i, selected, setOpen)}>{format?format(item.field):item.field}</div>}
            </li>
          )})}
        </ul>}
    </div>
  )
}