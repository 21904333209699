import React,{ useEffect, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import './WinterStorageChart1.css'

function WinterStorageChart1(props) {
  const {socket, response} = props;
  const [data, setData] = useState()

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="label">{`${label}: besökt ${payload[0].value}`} ggr</p>
        </div>
      )
    }
    return null
  }

  function CustomResponsiveContainer(props) {
    return (
      <div style={{ width: '100%', height: '100%', position: 'relative' }}>
        <div
          style={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: 0,
            left: 0
          }}
        >
          <ResponsiveContainer {...props} />
        </div>
      </div>
    );
  }

  useEffect(()=> {
    if (localStorage.getItem('statsTimeStamp')< (Date.now()- 3600000* 2)) { // update only is over 2 hours passed...3600000* 2
      socket.send(JSON.stringify({
        "action": "get_winterstorage_form_times_opened",
        "limit": "7",
        "order": "date"
      }))
    } else {
      if (localStorage.getItem('statsTimeStamp')) setData(JSON.parse(localStorage.getItem('stats')))
    }
  }, [])

  useEffect(()=> {
    if (response?.get_winterstorage_form_times_opened) {
      let responseData= response.get_winterstorage_form_times_opened_respone;
      responseData.timesOpened.map(data => {data.date = data.date.split('T')[0]})
      responseData.totalOpened= responseData.timesOpened.map(item => item.times).reduce((total, sum) => total + sum)
      setData(responseData)
      localStorage.setItem('stats', JSON.stringify(responseData))
      localStorage.setItem('statsTimeStamp', Date.now())
    }
  }, [response])
  
  if (data) {return (
    
    <div className="StatsContainer">
      <div className="Chart1" >
        <CustomResponsiveContainer>
          <LineChart
              data={data?.timesOpened}
              margin={{top: 10,right: 35,left: 0,bottom: 0}}>
            <XAxis stroke="#ffffff" dataKey="date" />
            <YAxis stroke="#ffffff" dataKey="times"/>
            <Tooltip content={<CustomTooltip />}/>
            <Line isAnimationActive={false} dataKey="times" stroke="#fff" strokeWidth={1} dot={false}/>
          </LineChart>
        </CustomResponsiveContainer>
      </div>
      <div>
        <div>
          <div>
            <div>
              {data?.totalOpened}
            </div>
            <div>
              {"Gånger\nöppnat"}
            </div>
          </div>
          <div>
            <div>
            {parseFloat(data?.avgTimeToFill[0]['AVG(seconds)']).toFixed(0)+'s'}
            </div>
            <div>
              {"AVG tid\n att fylla i"}
            </div>
          </div>
        </div>
        <div>
          <div>
            <div>
              {parseFloat(data?.totalOpened/data?.totalForms[0]['COUNT(_id)']).toFixed(1)+'%'}
            </div>
            <div>
              {"Fyllt i\nformuläret"}
            </div>
          </div>
          <div>
            <div>
              {parseFloat(data?.totalForms[0]['COUNT(_id)']/data?.totalAcceptedOffers[0]['COUNT(_id)']).toFixed(1)+'%'}
            </div>
            <div>
              {"Godkänt\noffert"}
            </div>
          </div>
        </div>
      </div>
    </div>
  )} else return(<></>)
}

export default WinterStorageChart1