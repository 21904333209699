import React, {useEffect, useState } from 'react'
import { Link, useLocation, useHistory } from 'react-router-dom'
import DropDown from './DropDown'
import './Nav.css'

function Nav(props) {
  const { screenWidth, screenHeight, logout, user, notifications } = props;
  const [toggle, setToggle] = useState(screenWidth< 750? false: true)
  const [selected, setSelected] = useState(1)
  const location = useLocation();
  const history = useHistory();
  const locationRef = location.path;

  const [navItems, setNavItems] = useState([{
    text: user.lang==='fi'?'TARJOUSKATSAUS':'OFFERTÖVERSIKT',
    path: '/winterstoragerequests',
    dropdown: [{
      field: 'Vinterförvaring',
      path: '/winterstoragerequests',
    }, {
      field: 'Arbeten',
      path: ''
    }, {
      field: 'Arkiverade',
      path: ''
    }],
    privilege_min: 3,
    notificationsObjName: "winterstorage",
    notifications: false
  }, {
    text: user.lang==='fi'?'MYYNTI':'FÖRSÄLJNING', 
    path: '/sales',
    privilege_min: 3,
    notificationsObjName: "sales",
    notifications: false    
  }, {
    text: user.lang==='fi'?'ASIAKKAAT':'KUNDER',
    path: '/customers',
    privilege_min: 3,
    notificationsObjName: "customers",
    notifications: false
  },{
    text: user.lang==='fi'?'TYÖMÄÄRÄT':'ARBETEN',
    path: '/tasks',
    privilege_min: 3,
    notificationsObjName: "tasks",
    notifications: false
  }, {
    text: user.lang==='fi'?'KALENTERI':'KALENDER',
    path: '/calendar',
    privilege_min: 3,
    notificationsObjName: "calendar",
    notifications: false
  }, {    
    text: 'ADMIN',
    path: '/admin',
    dropdown: [{
      field: 'Användare',
      path: '/admin/users'
    }, {
      field: 'Tillgängliga service',
      path: '/admin/storagesettings/formservices'
    }, {
      field: 'Upptagning/sjösättning',
      path: '/admin/storagesettings/pickuplocations'
    }, {
      field: 'Texter på formuläret',
      path: '/admin/storagesettings/texts'
    }, {
      field: 'Rapportera',
      path: '/admin/storagesettings/report'
    }],
    privilege_min: 5,
    notificationsObjName: "admin",
    notifications: false
  }, {
    text: user.lang==='fi'?'VENEITÄ':'BÅTAR',
    path: '/boats',
    privilege_min: 0,
    notificationsObjName: "boats",
    notifications: false
  }, {
    text: user.lang==='fi'?'KUVIA':'BILDER',
    path: '/images',
    privilege_min: 3,
    notificationsObjName: "images",
    notifications: false
  }, {
    text: user.lang==='fi'?'LASKUT':'FAKTUROR',
    path: '/bills',
    privilege_min: 3,
    notificationsObjName: "bills",
    notifications: false
  }, {
    text: user.lang=== 'fi'? 'TILI': 'KONTO',
    path: '/account',
    dropdown: [{
      field: user.lang=== 'fi'? 'Tiedot': 'Uppgifter',
      path: '/account/editinformation'
    }, {
      field: user.lang=== 'fi'? 'Vaihda salasana': 'Byt lösenord',
      path: '/account/changepassword'
    }, user.privileges>1&&{
      field: user.lang=== 'fi'? 'Profiilikuva': 'Profilbild',
      path: '/account/profilepicture'
    }],
    privilege_min: 0,
    notificationsObjName: "account",
    notifications: false
  }, {
    text: user.lang=== 'fi'? 'KIRJAUDU ULOS': 'LOGGA UT',
    action: logout,
    privilege_min: 0,
    notifications: false
  }])
 
  useEffect(()=>{ // update notification numbers... Updates from App.js
    setNavItems(prevState=> [...prevState, navItems.map(item => item.notifications=notifications[item.notificationsObjName])])
  }, [notifications]) 

  useEffect(()=> {
    if (location.pathname!== locationRef?.current) {
      navItems.forEach((item, i)=> {if (location.pathname.includes(item.path)) setSelected(i)})
    }
  }, [location.pathname])

  useEffect(()=>{
    if (toggle&& (screenWidth<600)) setToggle(!toggle)
    if (!toggle&& (screenWidth>1000)) setToggle(!toggle)
  },[screenWidth])

  useEffect(()=>{
    const elementHeight = document.getElementById("NavLeft").offsetHeight
    const elementAssign = document.getElementById("NavContainer")//document.getElementById("NavContainer")
    const mediaQuery= window.matchMedia('(min-height: '+(elementHeight+5)+'px)')
    if (mediaQuery.matches) {
      elementAssign.style.position="sticky"
      elementAssign.style.top="0%"
    } else {
      elementAssign.style.position="relative"
    }
  }, [screenHeight])

  return (
    <div className="relative">
    <div className="ShitContainer">
      <div id="NavContainer" className="NavContainer" style={{maxWidth: toggle? "300px": "0px"}}>                                   {/* Assigned */}
        <nav id="NavLeft" className="NavLeft">                                                                                      {/* Measured */}
          <input type="checkbox" style={{right: toggle?"5px":"-49px"}} checked={toggle} onChange={()=>setToggle(!toggle)}/>
          <div style={{right: toggle? "20px": "-27px"}}>
            <span></span>
            <span></span>
            <span></span>
          </div> 
          <ul>
            <div className="NavHeader"><span>Båt</span>huset</div>
            <li className="NavMainenu">{user.lang=== 'fi'? "Päävalikko": "Huvudmeny"}</li>
              {navItems.map((item, i)=> { if (user.privileges>= item.privilege_min) return (
                <li key={i} onClick={()=>setSelected(i)}style={{backgroundColor: selected=== i? '#e8e8e8': 'transparent', borderLeft: selected=== i? "5px solid #ff9925": "5px solid transparent"}}>
                  {!item.dropdown&& item.path?
                    <Link style={{color: selected=== i&& 'black'}} onClick={()=>{
                        screenWidth< 600&& setToggle(!toggle)
                      }} to={item.path}>{item.text}
                    </Link>
                  :
                  item.dropdown?
                    <>
                      <DropDown 
                        disableClose={true}
                        onchange={(e)=> {history.push(item.dropdown[e].path); screenWidth< 600&& setToggle(!toggle)}} 
                        disableposition={true} 
                        type="list" 
                        className="NavDropDown" 
                        menu={item.dropdown}><a style={{color: selected=== i&& 'black'}}>{item.text}</a>
                      </DropDown>
                    </>
                  :
                    <a onClick={item.action}>{item.text}</a>}
                  {item.notifications&&
                    <div className="NavNotification">{item.notifications}</div>}
                </li>              
              )})}
          </ul>
        </nav>
        <div className="NavOverflowContainer"/>
      </div>
    </div>
    </div>
  )
}

export default Nav