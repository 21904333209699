import React from 'react'
import ComponentContainer from './ComponentContainer'
import Resizer from 'react-image-file-resizer'
import { Buffer } from 'buffer'
import { serialize } from 'bson'

function ProfilePicture(props) {
  const { 
    user, 
    backendURL, 
    setSilentMSG 
  } = props

  async function UploadPicture(e) {
    e.preventDefault()
    const file= e.target[0].files[0]
    try {
      await Resizer.imageFileResizer(file, 400, 400, "JPEG", 100, 0,
        async (uri) => {
          let data = new FormData()
          data.append('image', uri)
          data.append('token', localStorage.getItem('token'))
          let res= await fetch('https://'+backendURL.current+'/img/upload/profilepicture', {
            body: data,
            method: 'POST',
            mode: 'same-origin',
            headers: {
              'Accept': 'application/json'
            } 
          })
          let resdata = await res.json();
          if (resdata.success) {
            setSilentMSG({text: "Uppladdning lyckades!", type: "success", onOK: () => {setSilentMSG(false)}})
          } else {
            setSilentMSG({text: "Något gick fel!", type: "error", onOK: () => {setSilentMSG(false)}})
          }
        }, "file"
      )
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <ComponentContainer header={user.lang==='fi'?"Profiilikuva":"Profilbild"} disablebuttons={true} maxwidth="480px">
      <img src={"https://"+backendURL.current+"/img/profiles/id"+user.id+".jpeg"} onError={(e)=>{e.target.style.display="none"}}/>
      <form onSubmit={UploadPicture}>
        <input type="file" id="fileUpload"/>
        <input type="submit" value="Ladda upp"/>
      </form>
    </ComponentContainer>
  )
}

export default ProfilePicture