import React, { useState, useEffect } from 'react' 
import OpenCustomer from './OpenCustomer'
import TableContainer from "./TableContainer"
import './Users.css'

function Customers(props) {
  const { setMessage, setSilentMSG, socket, response, setLoading } = props
  const [fields, setFields]  = useState([{
    field: "_id",
    toggled: true,
    exclude: true
  },{
    field: "first_name",
    toggled: true,
    exclude: false
  },{
    field: "last_name",
    toggled: true,
    exclude: false
  },{
    field: "email",
    toggled: true,
    exclude: false
  },{
    field: "telephone",
    toggled: true,
    exclude: false
  },{
    field: "city",
    toggled: false,
    exclude: false
  },{
    field: "street_address",
    toggled: false,
    exclude: false
  },{
    field: "postal_number",
    toggled: false,
    exclude: false
  },{
    field: "created",
    toggled: false,
    exclude: false
  },{
    field: "updated",
    toggled: false,
    exclude: false
  }])

  const actions =[{
    field: "Lägg till",
    action: (i, setOpen)=> {setUser({_id: null})}
  }, {
    field: "Öppna",
    action: (i, setOpen)=> {setUser(i)}
  }, {
    field: "Ta bort",
    action: (i, setOpen)=> {setMessage({text: 'Är du säker?', type: 'warning', onOK: () =>{
      socket.send(JSON.stringify({
        "action": "del_table1",
        "table": "customers",
        "id": i._id
      }))
      //setLoading(true)
      setMessage(false)
    }, onNO: ()=> {
      setMessage(false)
    }})}
  }, {
    field: "Avbryt",
    action: (i, setOpen) => setOpen(false)
  }]

  const [user, setUser] = useState()

  useEffect(()=> {
    if (response?.del_table1) {
      if (response.del_table1=== 'success') {
        setSilentMSG({text: 'Borttagen!', type: 'success', onOK:()=>setSilentMSG(false)})
      } else setMessage({text: response.del_table1, type: 'error', onOK:()=>setMessage(false)})
    }
  },[response])

  function fieldHeaderFormat(h) {
    if (h=== "first_name")         {return "Förnamn"} else
    if (h=== "last_name")          {return "Efternamn"} else
    if (h=== "email")              {return "E-post"} else
    if (h=== "telephone")          {return "Nummer"} else
    if (h=== "city")               {return "Ort"} else
    if (h=== "street_address")     {return "Gatuadress"} else
    if (h=== "postal_number")      {return "Postnummer"} else
    if (h=== "password_changed_at"){return "Lösenord bytt senast"} else
    if (h=== "privilege_level")    {return "Rättigheter"} else
    if (h=== "activated")          {return "Aktiverat"} else
    if (h=== "interlinked_with_id"){return "Interlinkat med ID"} else
    if (h=== "created")            {return "Skapad"} else    
    if (h=== "updated")            {return "Uppdaterad"} else    
      return h
  }

  function fieldValueFormat(value, field) {
    if (field=== 'activated') {
      if (value=== 0) return 'Nej'
      if (value=== 1) return 'Ja'
    } else
    if (field=== 'privilege_level') {
      if (value=== 5) {return "Admin"}            else
      if (value=== 4) {return "Faktura"}          else
      if (value=== 3) {return "Försäljare"}       else
      if (value=== 2) {return "Serviceman"}       else
      if (value=== 1) {return "Mikro-företagare"} else
      if (value=== 0) {return "Kund"}
    } else
    if (field=== 'created') {
      if (value) return value.split('T').join(' ').split('.000Z')[0]
    } else
    return value
  }

  return (
    <>
      {user?
        <OpenCustomer
          setMessage={setMessage} 
          setSilentMSG={setSilentMSG} 
          user={user} 
          setUser={setUser} 
          response={response} 
          socket={socket} 
          setLoading={setLoading}/>
      :
        <TableContainer
          fields={fields}
          setFields={setFields}
          actions={actions}
          DBtable="customers"
          header={"Kunder"}
          fieldHeaderFormat={fieldHeaderFormat}
          fieldValueFormat={fieldValueFormat}
          updateInterval={6}
          {...props}
        />}
    </>
  )
}

export default Customers