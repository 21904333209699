import React, { useEffect } from 'react'
import ComponentContainer from './ComponentContainer'

function OpenCustomer(props) {
  const {socket, setLoading, response, user, setUser, setMessage, setSilentMSG} = props
  const fields=[
    "_id",
    "name", 
    "fi", 
    "sv"
  ]
  
  function getUser() {
    socket.send(JSON.stringify({
      "action": "get_table1",
      "table": "winterstorage_language_settings",
      "fields": fields.map(s => '`'+s+'`').join(','),
      "id": user._id
    }))
    setLoading(true)
  }
  
  function save(e) {
    e.preventDefault()
    socket.send(JSON.stringify({
      "action": "save_table1",
      "table": "winterstorage_language_settings",
      "object": user
    }))
    setLoading(true)
  }

  useEffect(()=> {
    if (response?.get_table1) {
      setUser(...response.get_table1)
      setLoading(false)
    } else if (response?.save_table1) {
      if (response.save_table1==='success') {
        setSilentMSG({type: 'success', text: 'Sparat', onOK:()=>setSilentMSG(false)})
      } else setMessage({type: 'error', text: response.save_table1, onOK: ()=> setMessage(false)})
      setLoading(false)
    }
  }, [response])

  useEffect(()=> {
    if (user._id!==null)getUser()
  },[])
  return (
    <ComponentContainer header="Kund" maxwidth="380px" onClose={()=> setUser()}>
      <form className="ChangePasswordForm" onSubmit={save}>
        <div className="ChangePasswordContainer">
          <p>Fält</p>
          <input 
            disabled={true}
            type="text" 
            name= "name"
            value={user.name|| ''}/>
          <p>Finska</p>
          <textarea 
            spellCheck={false}
            style={{height: "200px", resize: "none", outline: "none"}}
            type="text" 
            name="finska"
            value={user.fi|| ''} 
            onChange={e=> setUser(prevState => ({...prevState, fi: e.target.value}))} />
          <p>Svenska</p>
          <textarea 
            spellCheck={false}
            style={{height: "200px", resize: "none", outline: "none"}}
            type="text" 
            name="svenska"
            value={user.sv|| ''} 
            onChange={e=> setUser(prevState => ({...prevState, sv: e.target.value}))} />
          <div className="PasswordButtonContainer">
            <input
              type="submit" 
              value="SPARA" 
              className="PasswordSaveButton"/> 
          </div>
        </div>
      </form>
    </ComponentContainer>
  )
}

export default OpenCustomer