import React, { useEffect, useState} from 'react'
import './ViewOffer.css';

function ViewOffer({pathid, pathkey, setMessage, setLogin, backendURL}) {
  const [table, setTable]                             = useState({})
  const [lang, setLang]                               = useState()
  const [locations, setLocations]                     = useState([])
  const [services, setServices]                       = useState([])
  const [wantAccount, setWantAccount]                 = useState(false)
  const [customerComment, setCustomerComment]         = useState(false)
  const [customerCommentText, setCustomerCommentText] = useState("")
  const [acceptOfferInput, setAcceptOfferInput]       = useState(true)
  const [acceptTerms, setAcceptTerms]                 = useState(false)
  const [editable, setEditable]                       = useState(true)
  const [openTerms, setOpenTerms]                     = useState(false)
  const [errorText, setErrorText]                     = useState("")
  
  const [addressError, setAddressError]               = useState({street: '', city: '', postal: ''})

  const [street, setStreet] = useState('')
  const [city, setCity]     = useState('')
  const [postal, setPostal] = useState('')
  const [postalText, setPostalText] = useState('')

  const Terms = {
    sv: [
      "VINTERFÖRVARINGSKONTRAKT",
      "Undertecknade Kimitoöns Båtcenter och båtägaren har gemensamt uppgjort överenskommelse:",
      "§1\nKimitoöns Båtcenter emottar och uppbevarar, för båtagarens räkning under vintersäsongen på omståend sida nämnda farkost.",
      "§2\nSärskild överenskommelse uppgöres vid reparations- och / eller ändringsarbeten på båt eller utrustning.",
      "§3\nBåtägaren erlägger i enlighet med § 1 nämnda överenskommelse ersättningen för förvaringstiden som upphör den 31.5. Ersättningen erläggs omedelbart efter det att detta slipförvaringskontrakt uppgjorts med Kimitoöns Båtcenter.",
      "§4\nKimitoöns Båtcenter ansvarar ej för skador på båt, motor eller annan utrustning som uppkommit på grund av naturkrafter eller p.g.a. skadegörelse under förvaring på slipen, vid dessa kajer eller i samband med ankring på slipens vattenområde. Kimitoöns Båtcenter ansvarar ej heller för ev. skador åsamkade av utomstående person.",
      "§5\nBåtägare eller övrig utomstående person äga ej rätt att under förvaringstiden vistas eller utföra något arbete på båten. Särskild överenskommelse kan dock i undantagsfall träffas.",
      "§6\nIfall farkosten med tillhörande utrustning inte är försäkrad kräves att båtägaren försäkrar densamma samt utrustningen under förvaringstiden samt då båten lämnas vid Kimitoöns Båtcenters kajer.",
      "§7\nTidpunkten för farkostens avhämtande bör meddelas Kimitoöns Båtcenter minst en 3-4 veckor i förväg. Vid större reparations- och / eller målningsarbeten, minst fyra (4) veckor i förväg. Farkosten bör avhämtas senast tre (3) dagar efter angiven avhämtningsdatum. I annat fall debiteras för varje påbörjet dygn platshyra.",
      "§8\nBåtägaren har rätt att överlåta detta förvaringskontrakt till annan person om Kimitoöns Båtcenter skriftligen godkänner överlåtelsen."
    ],
    fi: [
      "TELAKKAVARASTOINTISOPIMUS",
      "Allekirjoittaneet Kemiönsaaren Venekeskus ja veneen omistaja ovat yhdessä solmineet varastointisopimuksen seuraavin ehdoin:",
      "§1\nAllekirjoittaneet Kemiönsaaren Venekeskus ja veneen omistaja ovat yhdessä solmineet varastointisopimuksen seuraavin ehdoin:",
      "§2\nKemiönsaaren Venekeskus vastaanottaa ja säilyttää asiakkaan lukuun talvikauden yli kääntöpuolella mainitun aluksen.",
      "§3\nVeneen omistaja suorittaa § 1 mainitun korvauksen säilytyksestä, joka päättyy 31.5. Korvaus suoritetaan välittömästi tämän sopimuken solmimisen jälkeen Kemiönsaaren Venekeskukselle.",
      "§4\nKemiönsaaren Venekeskus ei vastaa sellaisista vaurioista veneessä, moottorissa tai varustuksessa, jotka johtuvat luonnonvoimista tai ilkivallasta telakalla, sen laitureissa tai ankkuroitaessa sen vesialueella. Kemiönsaaren Venekeskus ei vastaa ulkopuolisen henkilön mahdollisesti aiheuttamista vahingoista.",
      "§5\nVeneen omistajalla tai telakan henkilökuntaan kuulumattomalla ei ole oikeutta oleskeluun eikä korjausten suorittamiseen telakka-alueella varastoinnin aikana. Erityisistä syistä voidaan tähän kuitenkin myöntää lupa.",
      "§6\nMikäli alus ja sen varustus ei ole vakuutettu vaaditaan omistajaa vakuuttamaan ne varastoinnin ajaksi ja sataman ankkuroinnin ajaksi",
      "§7\nAluksen noutamisen ajankohta on ilmoitettava Kemiön Venekeskukselle viimeistään 3-4 viikkoa ennen noutoa, suurempiin korjaus- ja / tai maalaustöiden ollessa kyseessä viimeistään 4 viikkoa ennen. Alus on noudettava viimeistään 3 vrk ilmoitetun ajankohdan jälkeen. Tämän jälkeen peritään jokaiselta alkavalta vuorokaudelta",
      "§8\nVeneen omistajalla on oikeus siirtää tämä sopimus toiselle henkilölle mikäli Kemiönsaaren kirjellisesti siirron hyväksyy."
    ]
  }


  function fieldHeader(h) {
    if (h==="customer_name") {return lang==="fi"?"Nimi":"Namn"} else
    if (h==="customer_email") {return lang==="fi"?"Sähköposti":"E-post"} else
    if (h==="customer_phone") {return lang==="fi"?"Puhelinnumeroa":"Telefonnummer"} else
    if (h==="engine_internal") {return lang==="fi"?"Sisäperämoottori":"Innombordare"} else
    if (h==="price") {return "Pris"} else
    if (h==="customer_accepted_offer") {return "Accepterat"} else
    if (h==="customer_viewed_offer") {return "Öppnat"} else
    if (h==="created_time") {return "Datum"} else
    if (h==="customer_informed") {return "Fått svar"} else
    if (h==="price_set_by_id") {return "Priset bestämt av"} else
    if (h==="customer_informed_by_id") {return "Kunden kontaktad av"} else
    if (h==="comment_to_customer") {return "Kommentar till kunden"} else
    if (h==="lang") {return "Språk"} else
    if (h==="boat_width") {return lang==='fi'?"Leveys": "Bredd"} else
    if (h==="boat_length") {return lang==='fi'?"Pituus": "Längd"} else
    if (h==="engine_amount") {return lang==="fi"?"Moottoreiden määrä":"Motormängd"} else
    if (h==="discount") {return lang==='fi'?"Alennuskoodi":"Rabattkod"} else
    if (h==="boat_register") {return lang==='fi'?"Rekisteri":"Register"} else
    if (h==="engine_cycle") {return lang==='fi'?"Moottorin tahtimäärä":"Motorns taktmängd"} else
    if (h==="boat_other_services") {return "Annan service"} else
    if (h==="pickup_datetime") {
      if (table.form.pickup_and_launch==="no"||table.form.pickup_and_launch===undefined) {return lang==='fi'?"Säilytyksen alkamispäivä":"Förvaringens startdatum"}
      return lang==='fi'?"Veneen noutopäivä":"Båtens upptagningsdatum"
    } else
    if (h==="launch_datetime") {
      if (table.form.pickup_and_launch==="no"||table.form.pickup_and_launch===undefined) { return lang==='fi'?"Säilytyksen loppumispäivä":"Förvaringens slutdatum"}
      return lang==='fi'?"Veneen vesillelaskupäivä":"Båtens sjösättningsdatum"
    } else
    if (h==="boat") {return lang==='fi'?"Vene":"Båt"} else
    if (h==="already_customer") {return "Redan kund"} else
    if (h==="waxing") {return "Vaxning"} else
    if (h==="antifouling_overpaint") {return "Påmålning av bottenfärg"} else
    if (h==="winterstorage_type") {return lang==='fi'?"Säilytyksen tyyppi":"Förvaringens typ"} else
    if (h==="pickup_and_launch") {return lang==="fi"?"Nouto/Vesillelasku":"Upptagning/Sjösättning"} else
    if (h==="cleaning") {return "Städning"} else
    if (h==="engine_hp") {return lang==='fi'?"Moottorin tehoa (hv)":"Motoreffekt (hk)"} else
    if (h==="engine") {return lang==='fi'?"Moottori":"Motor"} else
    if (h==="engine_seasonal_maintenance") {return "Motorns säsongsservice"} else
    if (h==="pickup_location") {return lang==='fi'?"Nouto/Vesillelasku paikka":"Upptagning/sjösättning plats"} else
    if (h==="pickup_other") {return lang==='fi'?"Muu paikka":"Annan plats"} else
    return h
  }

  function formatFieldValue(f, key) {
    if (key==="customer_name") {return f.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())} else
    if (key==="customer_accepted_offer") {return f===0?"Nej":"Ja"} else
    if (key==="customer_viewed_offer") {return f===0?"Nej":"Ja"} else
    if (key==="created_time") {return f.split('T')[0]} else
    if (key==="customer_informed") {return f===0||f===''?"Nej":"Ja"} else
    if (key==="comment_to_customer") {return f===null||f===''||f===0?"Ingen":f} else
    if (key==="comment_to_others") {return f===null||f===''||f===0?"":f} else
    if (f==="yes") {return lang==='fi'?"Kyllä":"Ja"} else
    if (f==="no") {return lang==='fi'?"Ei":"Nej"} else 
    if (f==="sv") {return "Svenska"} else
    if (f==="fi") {return "Finska"} else
    if (f==="warm") {return lang==='fi'?"Lämmin":"Varm"} else
    if (f==="cold") {return lang==='fi'?"Kylmä":"Kall"} else
    if (key==="engine_cycle") {return f==="4-stroke"?lang==='fi'?"4-tahti":"4 takt":lang==='fi'?"2 tahti":"2 takt"} else
    if (key==="already_customer") {return f===true?"Ja":"Nej"} else
    if (key==="pickup_location") {
      return locations.map((location, i) => {if (f=== location.value) return location[lang]})
    } else
    return f
  }

  const storageFields = [
    "pickup_datetime",
    "launch_datetime",
    "pickup_and_launch",
    "pickup_location",
    "pickup_other",
    "winterstorage_type"
  ]

  const customerFields = [
    "customer_name",
    "customer_email" ,
    "customer_phone"
  ]

  const boatFields = [
    "boat",
    "boat_length",
    "boat_width",
    "boat_register"
  ]

  const engineFields = [
    "engine",
    "engine_amount",
    "engine_cycle",
    "engine_hp",
    "engine_internal"
  ]

  async function getOffer() {
    try {
      let res= await fetch('https://'+backendURL.current+'/customer/viewoffer?id='+ pathid+ '&key='+ pathkey, {
        method: 'GET',
        mode: 'same-origin',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        } 
      })
      let data = await res.json();

      if (res.status===200) {
        // Formating
        data.offer.form= JSON.parse(data.offer.form)
        data.offer.customer_informed_by= (data.offer.informed_by_firstname+ ' '+ data.offer.informed_by_lastname).replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())
        data.offer.customer_name = data.offer.customer_name.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())
        delete data.offer.informed_by_firstname
        delete data.offer.informed_by_lastname
        if (data.offer.form.engine_amount< 2) delete data.offer.form.engine_amount
        //if (data.offer.form.pickup_location!== undefined) delete data.offer.form.pickup_and_launch
        if (data.offer.form.pickup_and_launch=== 'no') { 
          delete data.offer.form.pickup_location // ****************************************************************************
          delete data.offer.form.pickup_and_launch // *********************** EDITED 3.8
        }
        (!data.address.street||!data.address.city||!data.address.postal_number)?
          setPostalText(data.offer.form.lang==='fi'?"Tarjouksen hyväksymiseen tarvitaan osoitetietosi":"För accepterad offert behöver vi dina adressuppgifter"):
          setPostalText(data.offer.form.lang==='fi'?"Ystävällisesti tarkista osoitetietosi":"Vänligen granska era adressuppgifter")
        setStreet(data.address.street)
        setCity(data.address.city)
        setPostal(data.address.postal_number)
        // End formating
        setLang(data.offer.form.lang)
        setLocations(data.locations)
        setServices(data.services)
        setTable(data.offer)
        if (data.editable===false) {
          setMessage({text: data.status, type: '', lang: lang, onOK: () => {setMessage({text: ''})}})
          setEditable(false)
        }
      } else {
        throw data.message
      }
    } catch (e) {
      setMessage({text: e, lang:lang, type: "error", onOK: () =>{
        setMessage({text: ''})
      }})
    }
  }

  async function acceptOffer(e) {
    e.preventDefault()
    if (!acceptTerms) {
      setErrorText(lang==='fi'?"Ehdot on luettava ja hyväksyttävä":"Villkoren måste läsas och godkännas")
      return
    }
    if (street.length< 4) setAddressError(prevState => ({...prevState, street: lang==='fi'?"Katuosoite puuttuu!":"Gatuadressen fattas!"}))
    if (city.length< 2) setAddressError(prevState => ({...prevState, city: lang==='fi'?"Postitoimipaikka puuttuu!":"Orten fattas!"}))
    if (postal.length< 3) setAddressError(prevState => ({...prevState, postal: lang==='fi'?"Postinumero puuttuu!":"Postnummer fattas!"}))
    if (street.length< 4 || city.length< 2 || postal.length< 3) return
    if (!editable) {
      setMessage({text: lang==='fi'?
        "Hyvä asiakas, tarjous odottaa jo käsittelyä myyjältä. Voit silti haluttaessa tehdä muutospyyntöä.":
        "Bästa kund, offerten väntar redan på behandling av försäljaren. Om du vill kan ändå göra förändringsbegäran.",
        lang: lang, onOK:()=> setMessage({text:''})})
      return
    }
    if (!acceptOfferInput) return
    try {
      let res= await fetch('https://'+backendURL.current+'/customer/acceptoffer?id='+ pathid+ '&key='+ pathkey, {
        method: 'POST',
        mode: 'same-origin',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({want_account: wantAccount, lang: table.form.lang, address: {street, city, postal}})
      })
      let data = await res.json();
      if (res.status===200) {
        setEditable(false)
        if (data.message) setMessage({text: lang==='fi'?"Tarjous hyväksytty! Kiitoksia!":"Offerten godkänd! Tack!", lang:lang, type: "success", onOK: () =>{
          setMessage({text: ""})
        }})
        if (data.email) setMessage({text: lang==='fi'?
          "Kiitos! Tarjous hyväksytty ja käyttäjätili luotu! Paina OK niin kirjaudut sisään.":
          "Tack! Offerten godkänd och användarkonto skapat! Tryck OK för att logga in.",
          type: "success", lang: lang, onOK: () => {
            setLogin({email: data.email, password: data.password})
            setMessage({text: ""})
          }
        })
      } else {
        throw data.error
      }
    } catch (e) {
      setMessage({text: e, lang: lang, type: "error", onOK: () =>{
        setMessage({text: ""})
      }})
    }
  }

  async function sendComment(e) { 
    e.preventDefault()
    try {
      let res= await fetch('https://'+backendURL.current+'/customer/savecustomercomment?id='+ pathid+ '&key='+ pathkey, {
        method: 'POST',
        mode: 'same-origin',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({customer_commented: customerCommentText, want_account: wantAccount, lang: table.form.lang})
      })
      let data = await res.json();
      if (res.status===200) {
        if (data.message) setMessage({text: lang==='fi'?"Kiitoksia! Muutospyyntö lähetetty!":"Tack! Ändringsbegäran skickad!", lang:lang, type: "success", onOK: () =>{
          setMessage({text: "", type: ""})
        }})
        if (data.email) setMessage({text: lang==='fi'?
          "Muutospyyntö lähetetty ja käyttäjätili luotu! Klikkaa OK niin pääset kirjautumaan sisään.":
          "Ändringsbegäran skickad! Klick OK så slipper du och logga in.",
          type: "success", onOK: () => {
            setLogin({email: data.email, password: data.password})
            setMessage({text: "", type: ""})
          }
        })
      } else {
        throw data.error
      }
    } catch (e) {
      setMessage({text: e, type: "error", onOK: () =>{
        setMessage({text: ""})
      }})
    }
  }

  useEffect(()=> {
    getOffer()
  }, [])

  return (
    <div className="ViewOfferContainer" onClick={()=> {setErrorText(""); setAddressError({street: '', city: '', postal: ''})}}>
      {Object.keys(table).length> 0&&
      <div className="ViewOffer">
        <h1>{lang==='fi'?"Talvisäilytyksen tarjous":"Offert på vinterförvaring"}</h1>
        <h2>{lang==='fi'?"Asiakas":"Kund"}</h2>
        <div className="ViewOfferContact">
          {customerFields.map((field, i) => {if (table[field]!== undefined) return (
            <div key={i} className="ViewOfferFieldContainer">
              <div className="ViewOfferHeader">
                {fieldHeader(field)+': '}
              </div>
              <div className="ViewOfferField">
                {formatFieldValue(table[field], field)}
              </div>
            </div>
          )})}
        </div>
        <h2>{lang==='fi'?"Vene":"Båt"}</h2>
        <div className="ViewOfferContact">
          {boatFields.map((field, i) => {if (table.form[field]!== undefined) return (
            <div key={i} className="ViewOfferFieldContainer">
              <div className="ViewOfferHeader">
                {fieldHeader(field)+': '}
              </div>
              <div className="ViewOfferField">
                {formatFieldValue(table.form[field], field)}
              </div>
            </div>
          )})}
        </div>
        <h2>{lang==='fi'?"Mottori":"Motor"}</h2>
        <div className="ViewOfferContact">
          {engineFields.map((field, i) => { if (table.form[field]!== undefined) return (
            <div key={i} className="ViewOfferFieldContainer">
              <div className="ViewOfferHeader">
                {fieldHeader(field)+': '}
              </div>
              <div className="ViewOfferField">
                {formatFieldValue(table.form[field], field)}
              </div>
            </div>
          )})}
        </div>
        <h2>{lang==='fi'?"Talvisäilytys":"Vinterförvaring"}</h2>
        <div className="ViewOfferContact">
          {storageFields.map((field, i) => { if (table.form[field]!== undefined) return (
            <div key={i} className="ViewOfferFieldContainer">
              <div className="ViewOfferHeader">
                {fieldHeader(field)+': '}
              </div>
              <div className="ViewOfferField">
                {formatFieldValue(table.form[field], field)}
              </div>
            </div>
          )})}
        </div>
        {table.launch_datetime!==''&&<div style={{maxWidth:"600px"}}>* {lang==='fi'?'Ystävällisesti ole yhteydessä myyjääsi ja sovi nosto/lasku-ajankohdasta, vähintään kaksi viikkoa ennen toivottua päivää.':'Vänligen kontakta din försäljare och kom överens tidpunkten för upptagning/sjösättning minst två veckor i förväg.'}</div>}
        <h2>{lang==='fi'?"Tilatut lisäpalvelut":"Beställda tilläggstjänster"}</h2>
        <div className="ViewOfferContact">
          {services.map((field, i) => { if (table.form[field.name]!== undefined&& table.form[field.name]!=='no') return (
            <div key={i} className="ViewOfferFieldContainerServices">
              <div className="ViewOfferHeader">
                {field[lang]+ ': '}
              </div>
              <div className="ViewOfferField">
                {lang==='fi'?"Kyllä":"Ja"}
              </div>
            </div>
          )})}
        </div>
        {table.form.discount&&
          <h2>{lang==='fi'?"Alennuskoodi":"Rabattkod"}: {table.form.discount}</h2>}
        <h2 style={{textAlign: 'center'}}>{lang==='fi'?"Myyjä":"Försäljare"}</h2>
          <div style={{justifyContent: 'center', display: 'flex'}}>
            <img style={{borderRadius:"50%", paddingTop: "10px"}} src={"https://"+backendURL.current+"/img/profiles/id"+table.customer_informed_by_id+".jpeg"} height="140px" onError={(e)=>{e.target.style.display="none"}}/>
          </div>
        <div className="ViewOfferContact" style={{justifyContent:"center"}}>
            <div className="ViewOfferField">{table.customer_informed_by}</div>
            <div className="ViewOfferField">{table.seller_telephone&&" "+ table.seller_telephone}</div>
        </div>
        {table.comment_to_customer&&
          <>
            <h2 >{lang==='fi'?"Myyjän kommentti":"Försäljarens kommentar"}</h2>
            <div className="ViewOfferFieldContainer">
              <textarea disabled={true} className="ViewOfferComment" value={table.comment_to_customer}></textarea>
            </div>
          </>}
        <h1 style={{textDecorationLine: 'underline'}}>{lang==='fi'?"Hinta":"Pris"}: {table.price+'€'}</h1>
        <div className="CustomerWantAccountCont">
          <div className="CustomerWantAccount">
            <div>
              <input id="wantChange" type="checkbox" value={customerComment} onChange={()=> {setCustomerComment(!customerComment); setAcceptTerms(false)}}/>
              <label htmlFor="wantChange">
                {lang==='fi'?
                  "Haluan muuttaa tarjouksen tai kysyä jotain":
                  "Jag vill ändra på offerten eller ställa frågor"}
              </label><br/>
              {!customerComment&&<>
              {errorText&&<div style={{position: 'relative'}}><div className="PasswordError"><p>{errorText}</p></div></div>}
              <input id="acceptTerms" type="checkbox" value={acceptTerms} onChange={()=> setAcceptTerms(!acceptTerms)}/>
              <label htmlFor="acceptTerms">
                {lang==='fi'?
                  "Olen lukenut ja hyväksyn ":
                  "Jag har läst och godkänner "}
              </label>
              <span style={{textDecoration: 'underline', cursor:"pointer"}} onClick={()=> setOpenTerms(!openTerms)}>{lang==='fi'?"ehdot":"villkoren"}</span></>}
            </div>
            {!table.customer_has_useraccount&&
            <div>
              <input id="wantAccount" type="checkbox" value={wantAccount} onChange={()=> setWantAccount(!wantAccount)}/>
              <label htmlFor="wantAccount">
                {lang==='fi'?
                  "Haluan käyttäjätilin Båthuset-sovellukseen":
                  "Jag beställer ett användarkonto till Båthuset-applikationen"}
              </label>
            </div>}
          </div>
        </div>
        {openTerms&&
          <div style={{border: "1px solid black", position: "fixed", maxHeight:"90vh", maxWidth:"700px", left:"50%", top:"50%", transform: "translate(-50%, -50%)", overflow: "auto"}}>
            <div style={{backgroundColor:"#fff", minWidth:"380px", padding: "10px"}}>
              {Terms[lang].map((s, i)=> 
                <div key={i} style={{textAlign: i===0||i===1?"center":"justify", textJustify:"inner-word", padding: "0", margin: "0"}}>
                  <span style={{whiteSpace:"pre-line",fontSize: i===0?"1.7rem":"1rem"}}>{s}</span>
                  {i!==0&&<><br/><br/></>}
                </div>)}
                <form style={{display:"flex", justifyContent:"center", paddingBottom:"60px"}} onSubmit={(e)=> {
                  e.preventDefault()
                  setOpenTerms(false)
                }}>
                  <input type="submit" style={{width:"160px"}} className="PasswordSaveButton" value={lang==='fi'?"SULJE":"STÄNG"}/>
                </form>
            </div>
          </div>}
        {customerComment&&
        <div className="ViewOfferContact">
          <div className="ViewOfferFieldContainer">
            <div className="ViewOfferCustomerCommentCont">
              {lang==='fi'?"Mitkä muutokset haluat?":"Vilka ändringar vill du ha?"}
              <textarea className="ViewOfferCustomerComment" value={customerCommentText} onChange={(e)=> setCustomerCommentText(e.target.value)}/>
            </div>
          </div>
        </div>}
        {acceptTerms&&!customerComment&&
          <div style={{display: 'flex', flexDirection: 'column', alignContent: 'center'}}>
            <div style={{textAlign: 'center'}}>{postalText}</div>
            <div style={{display:"flex", flexDirection: 'column', maxWidth: '300px', alignSelf:'center', width: '100%', padding: "8px", marginTop: '10px',border:'1px solid #aaa', borderRadius: '5px'}}>
              {addressError.street&&<div style={{position: 'relative'}}><div className="PasswordError"><p>{addressError.street}</p></div></div>}
              <label htmlFor="street">{lang==='fi'?"Katuosoite":"Gatuadress"}:</label>
              <input style={{backgroundColor:'#fff', outline: 'none', border: '1px solid #bbb', padding: '5px', margin: '10px'}} type="text" name="street" value={street?street:''} onChange={(e)=> setStreet(e.target.value)}/>
              {addressError.city&&<div style={{position: 'relative'}}><div className="PasswordError"><p>{addressError.city}</p></div></div>}
              <label htmlFor="city">{lang==='fi'?"Postitoimipaikka":"Ort"}:</label>
              <input style={{backgroundColor:'#fff', outline: 'none', border: '1px solid #bbb', padding: '5px', margin: '10px'}} type="text" name="city" value={city?city:''} onChange={(e)=> setCity(e.target.value)}/>
              {addressError.postal&&<div style={{position: 'relative'}}><div className="PasswordError"><p>{addressError.postal}</p></div></div>}
              <label htmlFor="postal_number">{lang==='fi'?"Postinumero":"Postnummer"}:</label>
              <input style={{backgroundColor:'#fff', outline: 'none', border: '1px solid #bbb', padding: '5px', margin: '10px'}} type="text" name="postal_number" value={postal?postal:''} onChange={(e)=> setPostal(e.target.value)} />
            </div>
          </div>}
        <div className="ViewOfferButtonContainer">
          {!customerComment&&
            <form onSubmit={acceptOffer}>
              <input type="submit" value={lang==='fi'?"Hyväksyn tarjouksen!":"Jag godkänner offerten!"} className="ViewOfferButton ViewOfferButtonSubmit"/>
            </form>}
          {customerComment&&
            <form onSubmit={sendComment}>
              <input type="submit" value={lang==='fi'?"Lähetä muutospyyntö":"Skicka ändringsförfrågan"} className="ViewOfferButton ViewOfferButtonSubmit"/>
            </form>}
        </div>
      </div>}
    </div>
  )
}

export default ViewOffer