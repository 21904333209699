import React, { useEffect, useState } from 'react'
import ComponentContainer from './ComponentContainer'
import DropDown from './DropDown'

function OpenUser(props) {
  const {socket, setLoading, response, user, setUser, setMessage, setSilentMSG, backendURL} = props
  const [thisUser, setThisUser] = useState(user)
  const lang = user.lang|| 'sv'

  const fields=[
    "_id", 
    "first_name", 
    "last_name",
    "telephone", 
    "street_address", 
    "city", 
    "postal_number",
    "lang"]
  
  function getUser() {
    socket.send(JSON.stringify({
      "action": "get_table1",
      "table": "users",
      "fields": fields.map(s => '`'+s+'`').join(','),
      "id": thisUser.id
    }))
    setLoading(true)
  }
  
  function save(e) {
    e.preventDefault()
    let saveUser= thisUser
    if (saveUser.customer_id==="") saveUser.customer_id= 0
    socket.send(JSON.stringify({
      "action": "save_table1",
      "table": "users",
      "object": thisUser
    }))
    setLoading(true)
  }

  useEffect(()=> {
    if (response?.get_table1) {
      setThisUser(...response.get_table1)
      setLoading(false)
    } else if (response?.save_table1) {
      if (response.save_table1==='success') {
        setSilentMSG({type: 'success', text: 'Sparat', onOK:()=>setSilentMSG(false)})
      } else setMessage({type: 'error', text: response.save_table1, onOK: ()=> setMessage(false)})
      setLoading(false)
    }
  }, [response])

  useEffect(()=> {
    if (user._id!==null) getUser()
  },[])

  return (
    <div style={{display: 'flex', flexDirection: "row", flexWrap: "wrap"}}>
    <ComponentContainer header={user.lang==='fi'?"Tiedot":"Uppgifter"} maxwidth="480px" disablebuttons={true}>
      <form className="ChangePasswordForm" onSubmit={save}>
        <div className="ChangePasswordContainer">
          <p>{lang=== 'fi'? "Etunimi": "Förnamn"}</p>
          <input 
            type="text" 
            name= "firstname"
            value={thisUser.first_name|| ''} 
            onChange={e=> setThisUser(prevState => ({...prevState, first_name: e.target.value.trim().toLowerCase()}))} />
          <p>{lang==='fi'?"Sukunimi":"Efternamn"}</p>
          <input 
            type="text" 
            name= "lastname"
            value={thisUser.last_name|| ''} 
            onChange={e=> setThisUser(prevState => ({...prevState, last_name: e.target.value.trim().toLowerCase()}))} />
          <p>{lang==='fi'?"Puhelin":"Telefon"}</p>
          <input 
            type="text" 
            name= "telephone"
            value={thisUser.telephone|| ''} 
            onChange={e=> setThisUser(prevState => ({...prevState, telephone: e.target.value.trim().toLowerCase()}))} />                  
          <p>{lang==='fi'?"Kunta":"Kommun"}</p>
          <input 
            type="text" 
            name= "city"
            value={thisUser.city|| ''} 
            onChange={e=> setThisUser(prevState => ({...prevState, city: e.target.value.trim().toLowerCase()}))} />       
          <p>{lang==='fi'?"Osoite":"Adress"}</p>
          <input 
            type="text" 
            name= "street_address"
            value={thisUser.street_address|| ''}  
            onChange={e=> setThisUser(prevState => ({...prevState, street_address: e.target.value.toLowerCase()}))} />
          <p>{lang==='fi'?"Postinumero":"Postnummer"}</p>
          <input 
            type="text" 
            name= "postal_number"
            value={thisUser.postal_number|| ''} 
            onChange={e=> setThisUser(prevState => ({...prevState, postal_number: e.target.value.trim().toLowerCase()}))} /> 
          <p>{lang==='fi'?"Kieli":"Språk"}</p>
          <DropDown 
              menu={[{field: lang=== 'fi'? "Suomi": "Finska"}, {field: lang=== 'fi'? "Ruotsi": "Svenska"}]} 
              type="list"
              onchange={(i, s, setOpen)=> {setThisUser(prevState => ({...prevState, lang: i=== 0? "fi": "sv"})); setOpen(false);}}>
            <div 
              onChange={e=> setThisUser(prevState => ({...prevState, lang: e.target.value}))}> 
              {thisUser.lang=== "fi" ?lang=== 'fi' ?"Suomi": "Finska": lang!== 'fi'? "Svenska": "Ruotsi"}  
            </div>
          </DropDown>   
          <div className="PasswordButtonContainer">
            <input
              type="submit" 
              value={lang=== 'fi'? "TALLENNA": "SPARA" }
              className="PasswordSaveButton"/> 
          </div>
        </div>
      </form>
    </ComponentContainer>
    </div>
  )
}

export default OpenUser