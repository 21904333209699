import React, { useState, useEffect, useRef } from 'react' 
import { useHistory, useLocation } from "react-router-dom"
import ComponentContainer from "./ComponentContainer"
import DropDown from "./DropDown"
import './Users.css'

function Users(props) {
  const { 

    search,
    setSearch,

    response,   // socket response
    socket,     // socket
    setLoading, // app root loading

    fields,     // available fields
    setFields,  // toggle

    actions,    // actions dropdown menu
    DBtable,    // Table of db table
    header,     // Header on Component window
    fieldHeaderFormat,  // Header formating of result
    fieldValueFormat,   // Value formating
    updateInterval      // Interval for updating total amount of list

  } = props

  const [table, setTable]    = useState([])
  
  const pageRef = useRef(15) // items per page
  const currentPageRef = useRef(1) // current page
  const total = useRef(localStorage.getItem('total'+ DBtable)|| 1)

  const [orderBy, setOrderBy] = useState()
  const [order, setOrder]     = useState('DESC')

  function getTotal() {
    setLoading(true)
    if (localStorage.getItem('total'+DBtable+'TimeStamp')< (Date.now()- 3600000* updateInterval)) { // update interval
      socket.send(JSON.stringify({
        "action": "get_table_total",
        "table": DBtable
      }))
    } else {
      if (localStorage.getItem('total'+DBtable+'TimeStamp')) total.current=JSON.parse(localStorage.getItem('total'+DBtable))
      getTable()
    }
  }

  function getTable() {
    setLoading(true)
    socket.send(JSON.stringify({
      "action": "get_table",
      "table" : DBtable,
      "fields": fields.filter(s => s.toggled).map(s => '`'+s.field+'`').join(','),
      "offset" : pageRef.current* (currentPageRef.current- 1),
      "limit": pageRef.current,
      "search": search,
      "order_by": orderBy,
      "order": order
    }))
  }

  useEffect(()=> {
    if (search==='') total.current=JSON.parse(localStorage.getItem('total'+ DBtable))
    getTable()
  }, [search])

  useEffect(()=> { // Optimization. checks if new fields are in results, otherwise fetches new ones
    if (table[0]) {
      const array1 = (Object.keys(table[0])), array2 = fields.filter(s=> s.toggled).map(field=> field.field)
      const checker = (arr, target) => target.every(v => arr.includes(v));
      if (!checker(array1, array2)) getTable()
    }
  }, [fields])

  useEffect(()=> {
    if (search!== '') setSearch('')
    getTotal()
  }, [])

  useEffect(()=> {
    if (response?.get_table_total) {
      localStorage.setItem('total'+DBtable, response.get_table_total["COUNT(_id)"])
      localStorage.setItem('total'+DBtable+'TimeStamp', Date.now())
    } else
    if (response?.get_table) {
      setTable(response.get_table)
    }
    setLoading(false)
  }, [response])

  useEffect(()=> {
    if (table.length>0 && table.length< pageRef.current) {
      total.current = pageRef.current* (currentPageRef.current- 1)+ table.length
    }
  }, [table.length])

  useEffect(()=> {
    getTable()
  }, [order, orderBy])

  return (
    <ComponentContainer header={header} disablebuttons={true}>
      <div className="UsersPages">
        <span className="relative noselect">
          <span>Fält </span>
          <DropDown 
            type="checkbox"
            menu={fields} 
            format={fieldHeaderFormat} 
            exclude="_id" 
            disableposition={false}
            disableOutsideClick={false}
            onchange={(i)=>{
              let newFields= fields
              newFields[i].toggled=!newFields[i].toggled
              setFields([...newFields])
            }}
          ><span>{"\u02C5"}</span></DropDown> 
        </span>
        <span>
          <span className="noselect">
            <span className="cursorpointer" onClick={()=>{
              if (currentPageRef.current>1) {currentPageRef.current-= 1; getTable()}
            }}>{"<"}</span> Sida 
            <span>{currentPageRef.current}/{Math.ceil((total.current)/ pageRef.current)===0?1:Math.ceil((total.current)/ pageRef.current)} </span>
            <span className="cursorpointer" onClick={()=> {
              if (currentPageRef.current<Math.ceil((total.current)/ pageRef.current)) {currentPageRef.current+= 1; getTable()}
              }}
            >{">"}
            </span>
          </span> 
        </span>
      </div>
      <div className="UsersContainer">
        <div className="UsersContainerTableBody">
          <div className="UsersContainerTableRow">
            {fields.map((field, i)=> { if (!field.exclude&& field.toggled) return (
              <div key={i} className="UsersContainerTableCell" style={{cursor: "pointer"}} onClick={()=> {
                if (orderBy=== field.field) {
                  if (order=== 'DESC') {
                    setOrder('ASC')
                  } else {
                    setOrder('DESC')
                  }
                } else {
                  setOrderBy(field.field)
                  setOrder('DESC')
                }
              }}>
                <div>{fieldHeaderFormat?fieldHeaderFormat(field.field):field.field}{orderBy===field.field?order==='DESC'?" ^":" \u02C5":""}</div>
              </div>
            )})}
          </div>
          {table?.map((user, i)=> { return (
            <DropDown menu={actions} key={i} selected={i} type="list" className="UsersContainerTableRow" onchange={(i, s, setOpen)=>actions[i].action(table[s], setOpen)}>
              {fields.map((field, i) => { if (!field.exclude&& field.toggled) return (
                <div key={i} className="UsersContainerTableCell">
                  <div className="relative">{fieldValueFormat?fieldValueFormat(user[field.field], field.field):user[field.field]}</div>
                </div>
              )})}
            </DropDown>
          )})}
        </div>
      </div>
    </ComponentContainer>
  )
}

export default Users