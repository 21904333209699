import React, { useEffect, useState} from 'react'
import { PDFViewer, Document, Page, Text, View, StyleSheet, Font } from '@react-pdf/renderer';

function ViewOfferPDF(props) {
  const {
    response,
    setMessage, 
    user,
    id,
    socket
  } = props

  const [table, setTable]                             = useState({})
  const [lang, setLang]                               = useState(user?.lang|| 'sv')
  const [locations, setLocations]                     = useState([])
  const [services, setServices]                       = useState([])

  function fieldHeader(h) {
    if (h==="customer_name") {return lang==="fi"?"Nimi":"Namn"} else
    if (h==="customer_email") {return lang==="fi"?"Sähköposti":"E-post"} else
    if (h==="customer_phone") {return lang==="fi"?"Puhelinnumeroa":"Telefonnummer"} else
    if (h==="engine_internal") {return lang==="fi"?"Sisäperämoottori":"Innombordare"} else
    if (h==="price") {return "Pris"} else
    if (h==="customer_accepted_offer") {return "Accepterat"} else
    if (h==="customer_viewed_offer") {return "Öppnat"} else
    if (h==="created_time") {return "Datum"} else
    if (h==="customer_informed") {return "Fått svar"} else
    if (h==="price_set_by_id") {return "Priset bestämt av"} else
    if (h==="customer_informed_by_id") {return "Kunden kontaktad av"} else
    if (h==="comment_to_customer") {return "Kommentar till kunden"} else
    if (h==="lang") {return "Språk"} else
    if (h==="boat_width") {return lang==='fi'?"Leveys": "Bredd"} else
    if (h==="boat_length") {return lang==='fi'?"Pituus": "Längd"} else
    if (h==="engine_amount") {return lang==="fi"?"Moottoreiden määrä":"Motormängd"} else
    if (h==="discount") {return lang==='fi'?"Alennuskoodi":"Rabattkod"} else
    if (h==="boat_register") {return lang==='fi'?"Rekisteri":"Register"} else
    if (h==="engine_cycle") {return lang==='fi'?"Moottorin tahtimäärä":"Motorns taktmängd"} else
    if (h==="boat_other_services") {return "Annan service"} else
    if (h==="pickup_datetime") {
      if (table.form.pickup_and_launch==="no"||table.form.pickup_and_launch===undefined) {return lang==='fi'?"Säilytyksen alkamispäivä":"Förvarings startdatum"}
      return lang==='fi'?"Noutopäivä":"Upptagningsdatum"
    } else
    if (h==="launch_datetime") {
      if (table.form.pickup_and_launch==="no"||table.form.pickup_and_launch===undefined) { return lang==='fi'?"Säilytyksen loppumispäivä":"Förvarings slutdatum"}
      return lang==='fi'?"Vesillelaskupäivä":"Sjösättningsdatum"
    } else
    if (h==="boat") {return lang==='fi'?"Vene":"Båt"} else
    if (h==="already_customer") {return "Redan kund"} else
    if (h==="waxing") {return "Vaxning"} else
    if (h==="antifouling_overpaint") {return "Påmålning av bottenfärg"} else
    if (h==="winterstorage_type") {return lang==='fi'?"Säilytys":"Förvaring"} else
    if (h==="pickup_and_launch") {return lang==="fi"?"Nouto/Vesillelasku":"Upptagning/Sjösättning"} else
    if (h==="cleaning") {return "Städning"} else
    if (h==="engine_hp") {return lang==='fi'?"Moottorin tehoa (hv)":"Motoreffekt (hk)"} else
    if (h==="engine") {return lang==='fi'?"Moottori":"Motor"} else
    if (h==="engine_seasonal_maintenance") {return "Motorns säsongsservice"} else
    if (h==="pickup_location") {return lang==='fi'?"Nouto/Vesillelasku":"Upptagning/Sjösättning"} else
    if (h==="pickup_other") {return lang==='fi'?"Muu paikka":"Annan plats"} else
    return h
  }

  function formatFieldValue(f, key) {
    if (key==="customer_name") {return f.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())} else
    if (key==="customer_accepted_offer") {return f===0?"Nej":"Ja"} else
    if (key==="customer_viewed_offer") {return f===0?"Nej":"Ja"} else
    if (key==="created_time") {return f.split('T')[0]} else
    if (key==="customer_informed") {return f===0||f===''?"Nej":"Ja"} else
    if (key==="comment_to_customer") {return f===null||f===''||f===0?"Ingen":f} else
    if (key==="comment_to_others") {return f===null||f===''||f===0?"":f} else
    if (f==="yes") {return lang==='fi'?"Kyllä":"Ja"} else
    if (f==="no") {return lang==='fi'?"Ei":"Nej"} else 
    if (f==="sv") {return "Svenska"} else
    if (f==="fi") {return "Finska"} else
    if (f==="warm") {return lang==='fi'?"Lämmin":"Varm"} else
    if (f==="cold") {return lang==='fi'?"Kylmä":"Kall"} else
    if (key==="engine_cycle") {return f==="4-stroke"?lang==='fi'?"4-tahti":"4 takt":lang==='fi'?"2 tahti":"2 takt"} else
    if (key==="already_customer") {return f===true?"Ja":"Nej"} else
    if (key==="pickup_location") {
      return locations.map((location, i) => {if (f=== location.value) return location[lang]})
    } else
    return f
  }

  const storageFields = [
    "pickup_datetime",
    "launch_datetime",
    "pickup_and_launch",
    "pickup_location",
    "pickup_other",
    "winterstorage_type"
  ]

  const customerFields = [
    "customer_name",
    "customer_email" ,
    "customer_phone",
  ]

  const boatFields = [
    "boat",
    "boat_length",
    "boat_width",
    "boat_register"
  ]

  const engineFields = [
    "engine",
    "engine_amount", 
    "engine_cycle",
    "engine_hp",
    "engine_internal"
  ]

  async function getOffer() {
    socket.send(JSON.stringify({
      "action": "get_form_for_pdf",
      "id": id
    }))
  }

  useEffect(() =>{
    if (response?.get_form_for_pdf) {
      if (response.get_form_for_pdf==='success') {
        let answer= response.answer
      
        // Formating
        answer.offer.form= JSON.parse(answer.offer.form)
        if (answer.offer.form.engine_internal==='yes') {answer.offer.form.engine_internal= lang==='fi'?"Sisäperämoottori":'Innombordare'} else {answer.offer.form.engine_internal= undefined}
        answer.offer.form.engine_hp += lang==='fi'?'hv':'hk'
        answer.offer.customer_informed_by= (answer.offer.informed_by_firstname+ ' '+ answer.offer.informed_by_lastname).replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())
        answer.offer.customer_name = answer.offer.customer_name.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())
        delete answer.offer.informed_by_firstname
        delete answer.offer.informed_by_lastname
        if (answer.offer.form.engine_amount< 2) delete answer.offer.form.engine_amount
        //if (data.offer.form.pickup_location!== undefined) delete data.offer.form.pickup_and_launch
        if (answer.offer.form.pickup_and_launch=== 'no') { 
          delete answer.offer.form.pickup_location // ****************************************************************************
          delete answer.offer.form.pickup_and_launch // *********************** EDITED 3.8
        }
        // End formating
        setLocations(answer.locations)
        setServices(answer.services)
        setTable(answer.offer)
      } else {
        setMessage({text: response.get_form_for_pdf, type:"error", onOK:()=>{setMessage(false)}})
      }
    }
  }, [response])

  useEffect(()=> {
    getOffer()
    const registerFont = () => {
      Font.register({family: "Montserrat", src:"http://fonts.gstatic.com/s/montserrat/v10/zhcz-_WihjSQC0oHJ9TCYC3USBnSvpkopQaUR-2r7iU.ttf"});
    }
    registerFont();
  }, [])

  // Create styles
  const styles = StyleSheet.create({
    page: {
      flexDirection: 'column',
      backgroundColor: '#ffffff',
      height: '100%',
    },
      ViewOfferContainer: {
      width: "100%",
      minHeight: "100vh",
      display: "flex",
      justifyContent: "center",
      backgroundColor: "#ffffff",
    },
    ViewOffer: {
      marginTop: "20pt",
      marginBottom: "20pt",
      padding: "30pt",
      backgroundColor: "#ffffff",
      display: "flex",
      flexDirection: "column",
      flex: "1 1 100%",
      maxWidth: "800px"
    },
    ViewOfferContact: {
      width:"50%",
      fontSize: "12pt",
      paddingBottom: "10pt",
      paddingTop: "2pt",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "flex-start"
    },
    ViewOfferFieldContainer: {
      whiteSpace: "nowrap",
      display: "flex",
      paddingLeft: "10pt",
      paddingRight: "10pt",
      paddingTop: "2pt",
      paddingBottom: "2pt",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "flex-start"
    },
    ViewOfferHeader: {
      whiteSpace: "nowrap",
      paddingRight: "3pt",
      fontSize: "12pt"
    },
    ViewOfferField: {
      whiteSpace: "nowrap"
    },
    ViewOfferFieldContainerServices: {
      display: "flex",
      paddingLeft: "10pt",
      paddingRight: "10pt",
      paddingTop: "2pt",
      paddingBottom: "2pt",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "flex-start"
    },
    ViewOfferComment: {
      paddingTop: "2pt",
      fontSize: "12pt",
      width: "100%",
      border: "1px solid #ccc",
      whiteSpace: "normal"
    }
  })

  return (
    <>
    {table&&
      <PDFViewer style={{minHeight: "85vh", minWidth: "100%"}}>
        <Document>
          <Page size="A4" style={styles.page}>
            {Object.keys(table).length> 0&&
              <View style={styles.ViewOffer}>
                  <View style={{display:"flex", flexDirection: "row", justifyContent:"space-between"}}>
                    <Text style={{fontSize:"28px"}}>{lang==='fi'?"Talvisäilytys":"Vinterförvaring"}</Text>
                    <Text style={{fontSize:"28px"}}>{table.form?.boat_register?table.form.boat_register:"_____________"}</Text>
                  </View>
                  <View style={[styles.ViewOfferContact, {alignItems:"flex-start", paddingTop: "10pt"}]}>
                  <Text style={{fontSize:"15pt"}}>{lang==='fi'?"Vene":"Båt"}</Text>
                    <View style={{display: "flex", flexDirection: "row", flexWrap: "wrap"}}>
                      {boatFields.map((field, i) => {if (table.form[field]!== undefined) return (
                        <View key={i} style={styles.ViewOfferFieldContainer}>
                          <Text style={styles.ViewOfferField}>
                            {formatFieldValue(table.form[field], field)},
                          </Text>
                        </View>
                      )})}
                    </View>
                  </View>
                <View style={{display: "flex", flexDirection: "row", flexWrap: "wrap"}}>
                  <View style={styles.ViewOfferContact}>
                  <Text style={{fontSize:"15pt"}}>{lang==='fi'?"Asiakas":"Kund"}</Text>
                    <View style={{display: "flex", flexDirection: "row", flexWrap: "wrap"}}>
                      {customerFields.map((field, i) => {if (table[field]!== undefined) return (
                        <View key={i} style={styles.ViewOfferFieldContainer}>
                          <Text style={styles.ViewOfferField}>
                            {formatFieldValue(table[field], field)},
                          </Text>
                        </View>
                      )})}
                        <View style={styles.ViewOfferFieldContainer}>
                          <Text style={styles.ViewOfferField}>
                            {formatFieldValue(table.form.lang, 'lang')},
                          </Text>
                        </View>
                    </View>
                  </View>
                  <View style={styles.ViewOfferContact}>
                    <Text style={{fontSize: "15pt"}}>{lang=== 'fi'? "Mottori": "Motor"}</Text>
                    <View style={{display: "flex", flexDirection: "row", flexWrap: "wrap"}}>
                      {engineFields.map((field, i) => { if (table.form[field]!== undefined) return (
                        <View key={i} style={[styles.ViewOfferFieldContainer, {paddingRight: "0"}]}>
                          <Text style={[styles.ViewOfferField]}>
                            {formatFieldValue(table.form[field], field)},
                          </Text>
                        </View>
                      )})}
                    </View>
                  </View>
                  <View style={styles.ViewOfferContact}>
                  <Text style={{fontSize:"15pt"}}>{lang==='fi'?"Talvisäilytys":"Vinterförvaring"}</Text>
                    {storageFields.map((field, i) => { if (table.form[field]!== undefined) return (
                      <View key={i} style={styles.ViewOfferFieldContainer}>
                        <Text style={styles.ViewOfferHeader}>
                          {fieldHeader(field)+': '}
                        </Text>
                        <Text style={styles.ViewOfferField}>
                          {formatFieldValue(table.form[field], field)}
                        </Text>
                      </View>
                    )})}
                  </View>
                  <View style={styles.ViewOfferContact}>
                   <Text style={{fontSize:"15pt"}}>{lang==='fi'?"Tilatut lisäpalvelut":"Beställda tilläggstjänster"}</Text>
                    {services.map((field, i) => { if (table.form[field.name]!== undefined&& table.form[field.name]!=='no') return (
                      <View key={i} style={styles.ViewOfferFieldContainerServices}>
                        <Text style={styles.ViewOfferHeader}>
                          {field[lang]+ ', '}
                        </Text>
                      </View>
                    )})}
                  </View>
                </View>
                <View style={styles.ViewOfferContact}>
                  <Text style={{fontSize:"15pt"}}>{lang==='fi'?"Myyjä":"Försäljare"}</Text>
                  <Text style={styles.ViewOfferFieldContainer}>{table.customer_informed_by}</Text>
                </View>
                {table.comment_to_customer!==""&&table.comment_to_customer!==undefined&&table.comment_to_customer!==null&&
                  <>
                    <Text style={{fontSize:"15pt"}}>{lang==='fi'?"Kommentit":"Kommentarer"}</Text>
                    <View style={styles.ViewOfferFieldContainer}>
                      <Text style={[styles.ViewOfferComment, {padding:"2pt"}]}>{table.comment_to_customer}</Text>
                    </View>
                  </>}
              <View style={styles.ViewOfferContact}>
                <Text>Pris: {table.price}</Text>
              </View>
              </View>}
          </Page>
        </Document>
      </PDFViewer>}
    </>
  )
}

export default ViewOfferPDF 