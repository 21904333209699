import React, { useState, useEffect, useRef } from 'react' 
import ReactDOM from 'react-dom'
import { useHistory} from "react-router-dom"
import ComponentContainer from "./ComponentContainer"
import DropDown from './DropDown'
import './Sales.css'
import ViewOfferPDF from './ViewOfferPDF'

function Sales(props) {
  const history = useHistory()
  const {response, socket, setLoading, search} = props
  const [table, setTable] = useState([])
  const [openPDF, setOpenPDF] = useState(false)

  const fields = [{
    field: "_id",
  },{
    field: "customer_name",
  },{
    field: "customer_email",
  },{
    field: "customer_informed_by_id",
  },{
    field: "customer_phone",
  },{
    field: "created_time",
  }]

/*    field: "Gör försäljning",
    action: (i, setOpen)=> console.log(i)
  }, {
    field: "Ta bort",
    action: (i, setOpen)=> console.log(i)
  }, { */

  const actions =[{
    field: "Öppna",
    action: (i, setOpen)=> history.push('/winterstoragerequests?id='+table[i]._id)
  }, {
    field: "Öppna som PDF",
    action: (i, setOpen) => {setOpenPDF(table[i]._id); setOpen(false)}
  }, {
    field: "Avbryt",
    action: (i, setOpen) => setOpen(false)
  }]

  const pageRef = useRef(15) // items per page
  const currentPageRef = useRef(1) // current page
  const totalAcceptedStorageForms = useRef(localStorage.getItem('TotalAcceptedStorageForms')|| 1)

  function getTotalAcceptedStorageForms() {
    setLoading(true)
    if (localStorage.getItem('TotalAcceptedStorageForms')< (Date.now()- 0)) { // dont update for 12h 3600000* 12
      socket.send(JSON.stringify({
        "action": "get_total_accepted_storage_forms"
      }))
    } else {
      if (localStorage.getItem('TotalAcceptedStorageForms')) totalAcceptedStorageForms.current=JSON.parse(localStorage.getItem('TotalAcceptedStorageForms'))
      getAcceptedStorageForms()
    }
  }

  const [orderBy, setOrderBy] = useState('created_time')
  const [order, setOrder]     = useState('DESC')

  function getAcceptedStorageForms() {
    setLoading(true)
    socket.send(JSON.stringify({
      "action": "get_accepted_storage_forms",
      "fields": fields.map(s => '`'+ s.field+ '`').join(','),
      "offset" : pageRef.current* (currentPageRef.current- 1),
      "limit": pageRef.current,
      "search": search,
      "order_by": orderBy,
      "order": order
    }))
  }

  useEffect(()=> {
    currentPageRef.current = 1;
    getAcceptedStorageForms()
  }, [search])

  useEffect(()=> {
    //setSearch('')
    getTotalAcceptedStorageForms()
  }, [])

  useEffect(()=> {
    getAcceptedStorageForms()
  }, [order, orderBy])

  useEffect(()=> {
    if (response?.get_total_accepted_storage_forms) {
      localStorage.setItem('TotalAcceptedStorageForms', response.get_total_accepted_storage_forms["COUNT(_id)"])
      localStorage.setItem('TotalAcceptedStorageFormsTimeStamp', Date.now())
      totalAcceptedStorageForms.current= response.get_total_accepted_storage_forms["COUNT(_id)"]
      getAcceptedStorageForms()
    } else
    if (response?.get_accepted_storage_forms) {
      response.get_accepted_storage_forms.map(s => s.created_time= s.created_time.split('T')[0])
      setTable(response.get_accepted_storage_forms)
      if (search.length>2 && response.get_accepted_storage_forms.length< pageRef.current) totalAcceptedStorageForms.current = response.get_accepted_storage_forms.length
    }
    setLoading(false)
  }, [response])

  function fieldHeader(h) {
    if (h=== "_id")                     {return "#"} else
    if (h=== "customer_informed_by_id") {return "Offert av"} else
    if (h=== "customer_name")           {return "Namn"} else
    if (h=== "customer_email")          {return "Email"} else
    if (h=== "customer_phone")          {return "Mobil"} else
    if (h=== "telephone")               {return "Nummer"} else
    if (h=== "city")                    {return "Postkontor"} else
    if (h=== "street_address")          {return "Gatuadress"} else
    if (h=== "telephone")               {return "Nummer"} else
    if (h=== "postal_number")           {return "Postnummer"} else
    if (h=== "user_account_id")         {return "Användar konto ID"} else
    if (h=== "created_time")            {return "Uppdaterad"} else
      return h
  } 

  return (
    <>
      {openPDF&&
        <div key="asd" style={{position: "fixed", zIndex: "3", minHeight: "100%", minWidth: "100%", top: "0px", left: "0px"}}>
          <ComponentContainer header="PDF" onClose={(e)=> {setOpenPDF(false)}}>
            <ViewOfferPDF id={openPDF} {...props}/>
          </ComponentContainer>
        </div>}
      <ComponentContainer header="Accepterade Offerter" maxwidth="1100px" disablebuttons={true}>
        <div className="UsersPages">
          <span></span>
          <span className="noselect">
            <span className="cursorpointer" onClick={()=>{
              if (currentPageRef.current>1) {currentPageRef.current-= 1; getAcceptedStorageForms()}
            }}>{"<"}</span> Sida 
            <span>{currentPageRef.current}/{Math.ceil((totalAcceptedStorageForms.current+1)/ pageRef.current)} </span>
            <span className="cursorpointer" onClick={()=> {
              if (currentPageRef.current<Math.ceil((totalAcceptedStorageForms.current)/ pageRef.current)) {currentPageRef.current+= 1; getAcceptedStorageForms()}
              }}
            >{">"}
            </span>
          </span>
        </div>
        <div className="UsersContainer">
          <div className="UsersContainerTableBody">
            <div className="UsersContainerTableRow">
              {fields.map((field, i)=> { return (
                <div key={i} className="UsersContainerTableCell" style={{cursor: "pointer"}}
                  onClick={()=> {
                    if (orderBy=== field.field) {
                      if (order=== 'DESC') {
                        setOrder('ASC')
                      } else {
                        setOrder('DESC')
                      }
                    } else {
                      setOrderBy(field.field)
                      setOrder('DESC')
                    }
                  }}>
                  <div>{fieldHeader(field.field)}{orderBy===field.field?order==='DESC'?" ^":" \u02C5":""}</div>
                </div>
              )})}
            </div>
            {table?.map((user, i)=> {return (
              <DropDown menu={actions} key={i} selected={i} type="list" className="UsersContainerTableRow" onchange={(i, s, setOpen)=>actions[i].action(s, setOpen)}>
                {fields.map((field, i) => { if (field.field!== 'customer_informed_by_id') {return (
                  <div key={i} className="UsersContainerTableCell">
                    <div className="relative">{user[field.field]}</div>
                  </div>
                )} else return (
                  <div key={i} className="UsersContainerTableCell">
                    <div className="relative">{user.informed_by_firstname+ ' '+ user.informed_by_lastname}</div>
                  </div>
                )})}
              </DropDown>
            )})}
          </div>
        </div>
      </ComponentContainer>
    </>
  )
}

export default Sales