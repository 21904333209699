import React, {useEffect, useState} from 'react'
import './SilentMessage.css'

function SilentMessage({delay, text, type, onOK, buttonText, lang}) {
  const [open, setOpen] = useState(false)
  const [backgroundColor, setBackgroundColor] = useState("#fff")

  const types = {
    warning: {backgroundColor: "#f2f25a", addDelay: 2000},
    error: {backgroundColor: "#e23636", addDelay: 10000},
    normal: {backgroundColor: "#ffffff", addDelay: 2000},
    success: {backgroundColor: "#5cd65c", addDelay: 2000}
  }

  useEffect(() =>{ // reopens if closing but new message arrives
    if (text!== '') setOpen(true)
    if (types[type]) {setBackgroundColor(types[type].backgroundColor)}
      else setBackgroundColor(types.normal.backgroundColor)
  }, [text])

  useEffect(()=> {
    const timer = setTimeout(() => {
      click(onOK)
      setOpen(false)
    }, delay|| text.split(" ").length* 220/ 60+ types[type]?types[type].addDelay: 2000)
    return () => {
      setOpen(true)
      clearTimeout(timer)
    }
  }, [delay])

  function click(action)  {
    setOpen(false)
    setTimeout(action, 200)
  }

  return (
    <div className="SilentMessageContainer">
      <div className="SilentMessage" style={{top: open?0:-100, backgroundColor: backgroundColor}}>
        <div style={{paddingRight: "8px"}}>{text}</div>
        {buttonText&&<div className="SilentMessageCloseButton" onClick={onOK?()=>click(onOK):null}>{buttonText}</div>}
      </div> 
    </div>
  )
}  

export default SilentMessage