import React, {useEffect, useState} from 'react'
import './LoginScreen.css'

function LoginScreen({success, setLoading, autoLogin, backendURL,setMessage}) {
  
  useEffect(() => {
    if (autoLogin.email) Login()
  }, [autoLogin])

  function validateEmail(email) {// eslint-disable-next-line
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase().trim());
  }

  const Login = async(e) => {
    if (e) e.preventDefault()
    let asd
    if (!autoLogin.email) {
      if (!validateEmail(username)) {
        setError(lang==="fi"?"Virheellinen käyttäjätunnus":'Ogiltig e-postaddress')
        setLoading(false)
        return
      }
      if (password.length<= 5) {
        setError(lang==="fi"?"Salasana liian lyhyt":'Lösenordet för kort')
        setLoading(false)
        return
      }
      asd= JSON.stringify({username, password})
    } else {
      asd= JSON.stringify({username: autoLogin.email, password: autoLogin.password})
    }
    setLoading(true)
    try {
      let res= await fetch('https://'+backendURL.current+'/user/login', {
        body: asd,
        method: 'POST',
        mode: 'same-origin',
        credentials: 'include',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        } 
      })
      let data = await res.json();
      if(res.status===200) {
        localStorage.setItem('token', data.token)
        success() // unmounts component from parent
      } else {
        setLoading(false);
        setError(lang==="fi"?"Väärä tunnus/salasana!":"Fel användarnamn/lösenord!")
      }
    } catch (e) {
      setLoading(false);
      setError(lang==="fi"?"Palvelin ei vastaa!":"Server svarar inte!")
    }
  }

  async function forgotPassoword(e) {
    e.preventDefault()
    if (!validateEmail(username)) {
      setError(lang==="fi"?"Virheellinen käyttäjätunnus":'Ogiltig e-postaddress')
      setLoading(false)
      return
    }
    try {
      let res= await fetch('https://'+backendURL.current+'/user/forgotpassword', {
        body: JSON.stringify({username}),
        method: 'POST',
        mode: 'same-origin',
        credentials: 'include',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        } 
      })
      let data = await res.json();
      console.log(data)
      if (data) setMessage({
        text: lang==='fi'?"Uusi salasana on lähetetty sähköpostiosoitteesi!":"Ett nytt lösenord skickat till din e-postadress!", 
        lang:lang, 
        type:"success", 
        onOK:()=>setMessage(false)})
    } catch (e) {
    }
  }

  const [error, setError]       = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [lang, setLang]         = useState('sv'); 
  const [forgotten, setForgotten] = useState(false)

  return (
    <div className="LoginWindowBackground">
      <div className="LoginWindowContainer">
        <div className="LoginWindow">
          <div className="PictureLeft"/>
          <form onSubmit={(e)=> {if (!forgotten){Login(e)} else {forgotPassoword(e)}}}>
            <div className="PictureRight"/>
            <div className="LoginWindowTextContainer">
              <h4>{lang==='fi'?"Tervetuloa Båthuset-sovellukseen!":"Välkommen till Båthuset!"}</h4>
              {!forgotten?<>
              <p>{lang==='fi'?"Asiakastiliä voi luoda talvisäilytyksen tarjouspyynnön yhteydessä.":"Kundkonto är möjligt att få i samband med offertförfrågan på vinterförvaring."}</p></>:
              <p>{lang==='fi'?"Syötä sähköpostiosoitettasi, niin lähetämme sinulle uuden salasanan.":"Ange din e-postadress så skickar vi nytt lösenord åt dig."}</p>}
            </div>
            <div>
              <div>
                <label htmlFor="username">{lang==="fi"?"Sähköposti":"E-post"}</label>
                <input onFocus={()=>setError('')} type="text" name="username" value={username} onChange={e=> {setUsername(e.target.value.trim())}}/>
              </div>
              {!forgotten&&
              <div>
                <label htmlFor="password">{lang==="fi"?"Salasana":"Lösenord"}</label>
                <input onFocus={()=>setError('')} type="password" name="password" value={password} onChange={e=> {setPassword(e.target.value)}}/>
              </div>}
            </div>
            {error&&
              <div className="LoginErrorContainer">
                <span className="LoginError">{error}</span>
              </div>}
            <input type="submit" value="OK"/>
            <p onClick={()=> {setForgotten(!forgotten); setError('')}}>{lang==="fi"?"Pyydä uusi salasana":"Begär nytt lösenord"}</p>
            <div className="LoginWindowLang" onClick={()=>{setError();setLang(lang==='sv'?'fi':'sv')}}>{lang==='sv'?"Suomeksi":"Svenska"}</div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default LoginScreen;
