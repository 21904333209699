import React, { useEffect, useState } from 'react'
import './Loading.css';

function Loading({delay, blur}) {
  const [show, setShow] = useState(false)

  useEffect(()=> {
    let prev = document.body.style.overflow
    const timer = setTimeout(() => {
      setShow(true)
      document.body.style.overflow = "hidden"
    }, delay|| 200)
    return () => {
      setShow(false)
      clearTimeout(timer)
      document.body.style.overflow = prev
    }
  }, [delay])

  return (
    <div className={"LoadingContainer"+ blur?"LoaderBlur":""}>
      {show&&<div className={"Loader"}/>}
    </div>
  )
}

export default Loading