import React, { useEffect } from 'react'
import ComponentContainer from './ComponentContainer'

function OpenCustomer(props) {
  const {socket, setLoading, response, user, setUser, setMessage, setSilentMSG} = props
  const fields=[
    "_id", 
    "first_name", 
    "last_name", 
    "email", 
    "telephone", 
    "street_address", 
    "city", 
    "postal_number",
    "user_account_id"]
  
  function getUser() {
    socket.send(JSON.stringify({
      "action": "get_table1",
      "table": "customers",
      "fields": fields.map(s => '`'+s+'`').join(','),
      "id": user._id
    }))
    setLoading(true)
  }
  
  function save(e) {
    e.preventDefault()
    socket.send(JSON.stringify({
      "action": "save_table1",
      "table": "customers",
      "object": user
    }))
    setLoading(true)
  }

  useEffect(()=> {
    if (response?.get_table1) {
      setUser(...response.get_table1)
      setLoading(false)
    } else if (response?.save_table1) {
      if (response.save_table1==='success') {
        setSilentMSG({type: 'success', text: 'Sparat', onOK:()=>setSilentMSG(false)})
      } else setMessage({type: 'error', text: response.save_table1, onOK: ()=> setMessage(false)})
      setLoading(false)
    }
  }, [response])

  useEffect(()=> {
    if (user._id!==null)getUser()
  },[])
  return (
    <ComponentContainer header="Kund" maxwidth="380px" onClose={()=> setUser()}>
      <form className="ChangePasswordForm" onSubmit={save}>
        <div className="ChangePasswordContainer">
          {user._id&&<><p>ID#</p>
          <input 
            disabled={true}
            type="text" 
            name= "firstname"
            value={user._id||''} 
            onChange={e=> setUser(prevState => ({...prevState, _id: e.target.value.trim().toLowerCase()}))} /></>}
          <p>Förnamn</p>
          <input 
            type="text" 
            name= "firstname"
            value={user.first_name||''} 
            onChange={e=> setUser(prevState => ({...prevState, first_name: e.target.value.trim().toLowerCase()}))} />
          <p>Efternamn</p>
          <input 
            type="text" 
            name= "lastname"
            value={user.last_name||''} 
            onChange={e=> setUser(prevState => ({...prevState, last_name: e.target.value.trim().toLowerCase()}))} />
          <p>E-post</p>
          <input 
            type="text" 
            name= "email"
            value={user.email||''} 
            onChange={e=> setUser(prevState => ({...prevState, email: e.target.value.trim().toLowerCase()}))} />
          <p>Nummer</p>
          <input 
            type="text" 
            name= "telephone"
            value={user.telephone||''} 
            onChange={e=> setUser(prevState => ({...prevState, telephone: e.target.value.trim().toLowerCase()}))} />                  
          <p>Kommun</p>
          <input 
            type="text" 
            name= "city"
            value={user.city||''} 
            onChange={e=> setUser(prevState => ({...prevState, city: e.target.value.trim().toLowerCase()}))} />       
          <p>Adress</p>
          <input 
            type="text" 
            name= "street_address"
            value={user.street_address||''}  
            onChange={e=> setUser(prevState => ({...prevState, street_address: e.target.value.toLowerCase()}))} />
          <p>Postnummer</p>
          <input 
            type="text" 
            name= "postal_number"
            value={user.postal_number||''} 
            onChange={e=> setUser(prevState => ({...prevState, postal_number: e.target.value.trim().toLowerCase()}))} /> 
          <p>Användarkonto ID#</p>
            <input 
              disabled={true}
              type="text" 
              name= "customer_id"
              value={user.user_account_id||''} 
              onChange={e=> setUser(prevState => ({...prevState, customer_id: e.target.value.trim().toLowerCase()}))} />
          <div className="PasswordButtonContainer">
            <input
              type="submit" 
              value="SPARA" 
              className="PasswordSaveButton"/> 
          </div>
        </div>
      </form>
    </ComponentContainer>
  )
}

export default OpenCustomer