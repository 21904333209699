import React, {useEffect, useRef, useState} from 'react'
import {BrowserRouter as Router, useHistory} from "react-router-dom"
import LoginScreen from './Components/LoginScreen'
import MainApp from './Components/MainApp'
import Loading from './Components/Loading'
import ViewOffer from './Components/ViewOffer'
import Unsubscribe from './Components/Unsubscribe'
import Message from './Components/Message'
import SilentMessage from './Components/SilentMessage'
import { WithDimensions } from './Components/WithDimensions';

import './App.css'

let socket= null

function App() {

  const [response, setResponse]     = useState() // server response to component props
  const [appState, setAppState]     = useState()  // gloabal app state
  const [loading, setLoading]       = useState(false) // global loading..
  const [user, setUser]             = useState({id: '', name: '', privileges: -1, lang: ''})
  const [pathid, setPathid]         = useState('') // used for viewoffer and unsubscribe !!
  const [pathkey, setPathkey]       = useState('') // used for viewoffer and unsubscribe !!
  const [message, setMessage]       = useState({text: "", type: ""}) // used for global interrupt messages
  const [login, setLogin]           = useState({}) // used by autologin...
  const [silentMSG, setSilentMSG]   = useState({delay: '', text: '', type: ''})
  const [search, setSearch]         = useState('')

  const socketRef                   = useRef(null)  // ref to socket
  const userRef                     = useRef({id: '', name: '', privileges: -1, lang: ''})
  const backendURL                  = useRef() // backend server URL diffrent for production...

  const ConnectSocket = (args) => {
    setLoading(true)
    const token = localStorage.getItem('token')
    if (token) {
      socket = new WebSocket(`wss://${backendURL.current}/ws?token=${token}`) // socket = new WebSocket(`wss://api.venekeskus.webnite.fi/ws?token=${token}`)
    } else {
      setAppState('login')
      setLoading(false)
    }

    socketRef.current = socket
    window.socket = socket
    const pong = () => {
      socket.send('pong')
      clearTimeout(socket.pingTimeout)
      socket.pingTimeout = setTimeout(() => {
        socket.close()
      }, 30000)
    }

    socket.onmessage = (e) => {
      let message
      if (e.data==="ping") {
        pong()
      } else {
        if (e.data?.includes('Authorized')){
          args= null
          try {
            message = JSON.parse(e.data)
            let name;
            try {
              name = message.Authorized.name.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
            } catch (e) { name = 'unknown' }
            setUser({
              id: message.Authorized.id, 
              privileges: Number(message.Authorized.privilege_level), 
              name: name,
              firstlogin: message.Authorized.firstlogin=== "true"? true: false,
              lang: message.Authorized.lang
            })
            userRef.current={
              id: message.Authorized.id, 
              privileges: Number(message.Authorized.privilege_level), 
              name: name,
              firstlogin: message.Authorized.firstlogin=== "true"? true: false,
              lang: message.Authorized.lang
            }
            setAppState('mainApp')

            //setUser({id: '', name: '', privileges: 0})
          } catch (e) { 
          }
        } else { // if not ping or Authorized then other server response
          try {
            setResponse(JSON.parse(e.data)) // try, or else break if not JSON
            //console.log(e.data)
          } catch (e) {
          }
        }
      }
      setLoading(false)
    }
    
    socket.onopen = (e) => {
      setLoading(false)
    }

    socket.onclose = (e) => {
      setLoading(false)
      if (e.wasClean) {
        if (!token) setAppState('login')
      }
      clearTimeout(socket.pingTimeout)
      if (token&&e.reason==!'logout') { // eslint-disable-line 
        setLoading(true)
        setTimeout(()=> {
          if (args==='reconnecting') { // try 2 times then stop trying
            ConnectSocket('reconnecting2')
          } else {
            ConnectSocket('reconnecting')
          }
        }, 1000)
      } else {
        setAppState('login')
      }
    }

    socket.onerror = (e) => {
      setLoading(false)
      if (args=='reconnecting2') {
        if (userRef.current.id) setMessage({text: "Server disconnection", type: "error", onOK: () => setMessage({text:"",type:""})})
        logout()  // eslint-disable-line
      }
    }
  }

  const logout = () => {
    setUser({id: '', name: '', privileges: -1, lang: ''})
    userRef.current= {id: '', name: '', privileges: -1, lang: ''}
    setAppState('login')
    localStorage.removeItem('token')
    fetch('https://'+backendURL.current+'/user/logout', {
      method: 'POST',
      mode: 'no-cors',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      } 
    }).then(socket.close(1000, 'logout'))
  }

  useEffect(()=> {
    // *************************** development ************************
    if (process.env.NODE_ENV!=='development') {
      backendURL.current='app.venekeskus.com/api'
    } else {
      backendURL.current='dev.webnite.fi/api'
    }
    // remake this at some point.... log window.location searchstring includes id key etc...
    // pathname === /viewoffer / /unsubscribe
    const unsubscribe= window.location.href.split('unsubscribe?')[1]
    const viewoffer= window.location.href.split('viewoffer?')[1]
    if (unsubscribe) {
      const path    = unsubscribe
      const idpath  = path.split('&')[0]
      const keypath = path.split('&')[1]
      const id      = idpath.split('=')[1]
      const key     = keypath.split('=')[1]
      if (id&& key) {
        setPathid(id)
        setPathkey(key)
        setAppState('unsubscribe')
      }
    } else if (viewoffer) {
      const path    = viewoffer
      const idpath  = path.split('&')[0]
      const keypath = path.split('&')[1]
      const id      = idpath.split('=')[1]
      const key     = keypath.split('=')[1]
      if (id&& key) {
        setPathid(id)
        setPathkey(key)
        setAppState('viewoffer')
      }
    } else if (localStorage.getItem('token')) {
      ConnectSocket()
    } else setAppState('login')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(()=> { // timeout for clearence between responses
    const timer = setTimeout(() => {
      if (response) {
        setResponse()
        setLoading(false)
      }
    }, 1000)
    return () => clearTimeout(timer);
  }, [response])

  return (
    <div className="App">
      {silentMSG.text&&
        <SilentMessage text={silentMSG.text} delay={silentMSG.delay} type={silentMSG.type} onOK={silentMSG.onOK} buttonText={silentMSG.buttonText} lang={silentMSG.lang}/>}
      {loading&&
        <Loading blur={user.id?false:true} delay={user.id?2000:20}/>}
      {message.text&& 
        <Message text={message.text} type={message.type} onOK={message.onOK} onNO={message.onNO} lang={message.lang}/>}
      {appState==='login'&&
        <LoginScreen backendURL={backendURL} setLoading={setLoading} setMessage={setMessage} setSilentMSG={setSilentMSG} success={()=> {setLogin({});ConnectSocket()}} autoLogin={login}/>}
      {appState==='viewoffer'&&
        <ViewOffer backendURL={backendURL} pathid={pathid} pathkey={pathkey} setMessage={setMessage} setLogin={(e)=>{setLogin(e);setAppState('login')}}/>}
      {appState==='unsubscribe'&&
        <Unsubscribe backendURL={backendURL}pathid={pathid} pathkey={pathkey} setMessage={setMessage}/>}
      {appState==='mainApp'&&
        <Router>
          <WithDimensions>
            <MainApp 
              backendURL={backendURL}
              search={search}
              setSearch={setSearch}
              setLoading={setLoading}
              setSilentMSG={setSilentMSG}
              setMessage={setMessage}
              user={user} 
              setUser={setUser} 
              logout={logout} 
              socket={socket} 
              response={response} 
              setResponse={setResponse} 
              setAppState={setAppState}/>
          </WithDimensions>
        </Router>}
    </div>
  );
}

export default App;
