import React, { useEffect, useState, forwardRef, useRef} from 'react';

import { useHistory, useLocation } from "react-router-dom"
import ComponentContainer from './ComponentContainer';
import './PickupLocations.css'

function FormServices(props) {
  const {setLoading} = props

  const [responseMessage, setResponseMessage] = useState('')
  const {user, socket, response} = props

  const dragStartRef= useRef(null)
  const dragOverRef= useRef(null)

  const [services, setServices]= useState([])

  const [deleteRow, setDeleteRow]= useState(-1)

  function getFormServices() {
    setLoading(true)
    socket.send(JSON.stringify({
      "action": "get_formservices"
    }))
  }

  function saveFormServices(e) {
    setLoading(true)
    e.preventDefault()
    socket.send(JSON.stringify({
      "action": "save_formservices",
      "object": [...services]
    }))
  }

  useEffect(()=> {
    if (response?.get_formservices?.length> 0) {
      response.get_formservices.sort((a, b) => { return a.order- b.order}) // order by location order
      response.get_formservices.map((r, i) => { return r.order= 1+ i} ) // if duplicate value re-arrange
      setServices(response.get_formservices)
    } else 
    if (response?.save_formservices) {
      if (response.save_formservices=== "Success") {setResponseMessage ('Sparat')} else
      if (response.save_formservices=== "Error") {setResponseMessage ('Nåt gick fel!')}
    }
    setLoading(false)
  }, [response])

  useEffect(()=> {
    getFormServices()
    return (()=> {
    })
  }, [])

  function fieldHeader(h) {
    if (h=== 'name') {return "Namn"} else
    if (h=== 'fi') {return "Finska"} else
    if (h=== 'sv') {return "Svenska"} else
    if (h=== 'price') {return "Pris"} else
    if (h=== 'show_price') {return "Visa pris"} else
    if (h=== 'price_per_hour') {return "€/h"} else 
      return h
  }

  function handleChange(e, i, key) {
    let newServices= services
    newServices[i][key]= e.target.innerHTML
    setServices([]) // cursor otherwise jumps to start
    setServices(newServices)
  }

  function handleShowPriceChange(i) {
    let newServices= services
    newServices[i].show_price= newServices[i].show_price=== 1? 0: 1
    setServices([...newServices])
  }

  function handleDragStart(e) {
    dragStartRef.current= e.target.id
  }

  function handleDragOver(e) {
    dragOverRef.current= e.target.id
  }

  function handleDragEnd(e) {
    if (dragOverRef.current!== null&&dragOverRef.current!=='') if (dragStartRef.current!== null&&dragStartRef.current!=='') {
      let newServices= services;
      let temp= newServices[dragOverRef.current].order
      newServices[dragOverRef.current].order= newServices[dragStartRef.current].order
      newServices[dragStartRef.current].order= temp
      newServices.sort((a, b) => { return a.order- b.order})
      setServices([...newServices])
    }
  }

  function deleteLocation(i) {
    let newServices= services
    newServices.splice(i, 1)
    setServices([...newServices])
    setDeleteRow()
  }

  function addLocation(i) {
    let newServices= services
    newServices.splice(i, 0, {_id: null, order: 0, name: "nytt", fi: "", sv: "", price_per_hour: "0.00", show_price: 0})
    newServices.map((r, i2) => { return r.order= 1+ i2} )
    setServices([...newServices])
  }

  return (
    <ComponentContainer header="Tillgänglig service" disablebuttons={true}>
        "Namn" är värdet som sparas, det är viktigt att det är riktgivande och unikt samt inte har mellanslag eller specialla bokstäver.
        Finska/Svenska kolumnerna är vad som syns på formuläret.
        Ordningen är den samma som i formuläret, och går att byta med att dra på raderna.
        OBS! Raderar man bort en rad så försvinner det också ifrån väntande offertförfrågningar.
      <div className="UsersContainer">
        <div className="UsersContainerTableBody">
          <div className="UsersContainerTableRow">
            {services[0]&&Object.keys(services[0]).map(key => { if (key!== '_id'&&key!=='order') return (
              <div key={key} className="UsersContainerTableCell">
                <div>{fieldHeader(key)}</div>
              </div>
            )})}
            <div className="UsersContainerTableCell">
              <div>Alternativ</div>
            </div>
          </div>
          {services?.map((service, i) => { return (
            <div 
              draggable="true"
              id={i}
              key={i} 
              onDragStart={handleDragStart}
              onDragOver={handleDragOver}
              onDragEnd={handleDragEnd}
              className="UsersContainerTableRow">
                {Object.keys(service).map(key => { if (key!== '_id'&& key!== 'order') return ( // remove _id and order from rendering
                  <div key={key} className="PickupLocationsTableCell">
                    {key!=='show_price'?
                      <div 
                        id={i}
                        spellCheck="false"
                        contentEditable="true" 
                        onInput={(e)=>handleChange(e, i, key)} 
                        suppressContentEditableWarning={true}>
                          {service[key]}
                      </div>
                        :
                      <div id={i} onClick={()=>{handleShowPriceChange(i)}}>{service[key]=== 1? "Ja": "Nej"}
                      </div>}
                  </div>
                )})}
                <div id={i} className="PickupLocationsTableCell">
                  <div className="PickupLocationsOptions">
                    {deleteRow!==i?
                      <>
                        <p onClick={()=>setDeleteRow(i)}>
                          Radera/
                        </p>
                        <p onClick={()=>addLocation(i)}>
                          Lägg till
                        </p>
                      </>
                    :
                      <p onClick={()=> deleteLocation(deleteRow)}>
                        Säker?
                      </p>
                    }
                  </div>
                </div>
            </div>
          )})}
        </div>
      </div>
      <form onSubmit={saveFormServices}>
        {responseMessage&&<p>{responseMessage}</p>}
        <input type="submit" className="PasswordSaveButton" value="SPARA"/>
      </form>
    </ComponentContainer>
  )
}

export default FormServices