import React, {useEffect} from 'react'
import './Footer.css'

function Footer(props) {
  useEffect(()=> {
  }, [])
  return (<></>)
  {/*    <div className="FooterContainer">
        <div className="Footer">
        </div>
      </div>
  }*/
  }
}

export default Footer