import React  from 'react'
import { Switch, Route } from "react-router-dom"
import Users from './Users'
import PickupLocations from './PickupLocations'
import AdminTexts from './AdminTexts'
import Report from './Report'
import './Account.css'
import './Admin.css'
import FormServices from './FormServices'

function Admin(props) {
  return ( 
    <Switch>
      <Route path='/admin/users'>
        <Users {...props}/>
      </Route>
      <Route path='/admin/storagesettings/pickuplocations'>
        <PickupLocations {...props}/>
      </Route>
      <Route path='/admin/storagesettings/formservices'>
        <FormServices {...props}/>
      </Route>
      <Route path='/admin/storagesettings/texts'>
        <AdminTexts {...props}/>
      </Route>
      <Route path='/admin/storagesettings/report'>
        <Report {...props}/>
      </Route>
    </Switch>
  )
}

export default Admin