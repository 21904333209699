import React, { useEffect, useState, forwardRef, useRef, useCallback } from 'react';

import { Switch, Route, useHistory, useLocation } from "react-router-dom"
import ComponentContainer from './ComponentContainer';
import Loading from "./Loading"
import './PickupLocations.css'

function PickupLocations(props, ref) {
  const location = useLocation()
  const history = useHistory()
  const [responseMessage, setResponseMessage] = useState('')
  const {user, socket, response, setResponse} = props
  const [loading, setLoading]= useState(true)

  const dragStartRef= useRef(null)
  const dragOverRef= useRef(null)

  const [locations, setLocations]= useState([])

  const [deleteRow, setDeleteRow]= useState(-1)

  function getPickupLocations() {
    setLoading(true)
    socket.send(JSON.stringify({
      "action": "get_pickuplocations"
    }))
  }

  function savePickupLocations(e) {
    setLoading(true)
    e.preventDefault()
    socket.send(JSON.stringify({
      "action": "save_pickuplocations",
      "object": [...locations]
    }))
  }
  
  useEffect(()=> {
    if (response?.get_pickuplocations?.length> 0) {
      response.get_pickuplocations.sort((a, b) => { return a.order- b.order}) // order by location order
      response.get_pickuplocations.map((r, i) => { return r.order= 1+ i} ) // if duplicate value re-arrange
      setLocations(response.get_pickuplocations)
    } else 
    if (response?.save_pickuplocations) {
      if (response.save_pickuplocations=== "Success") {setResponseMessage ('Sparat')} else
      if (response.save_pickuplocations=== "Error") {setResponseMessage ('Nåt gick fel!')}
    }
    setLoading(false)
  }, [response])

  useEffect(()=> {
    getPickupLocations()
    return (()=> {
    })
  }, [])

  function fieldHeader(h) {
    if (h=== 'value') {return "Value"} else
    if (h=== 'fi') {return "Finska"} else
    if (h=== 'sv') {return "Svenska"} else
    if (h=== 'price') {return "Pris"} else
    if (h=== 'show_price') {return "Visa pris"} else
    return h
  }

  function handleChange(e, i, key) {
    let newLocations= locations
    newLocations[i][key]= e.target.innerHTML
    setLocations([]) // cursor otherwise jumps to start
    setLocations(newLocations)
  }

  function handleShowPriceChange(i) {
    let newLocations= locations
    newLocations[i].show_price= newLocations[i].show_price=== 1? 0: 1
    setLocations([...newLocations])
  }

  function handleDragStart(e) {
    dragStartRef.current= e.target.id
  }

  function handleDragOver(e) {
    dragOverRef.current= e.target.id
  }

  function handleDragEnd(e) {
    if (dragOverRef.current!== null&&dragOverRef.current!=='') if (dragStartRef.current!== null&&dragStartRef.current!=='') {
      let newLocations= locations;
      let temp= newLocations[dragOverRef.current].order
      newLocations[dragOverRef.current].order= newLocations[dragStartRef.current].order
      newLocations[dragStartRef.current].order= temp
      newLocations.sort((a, b) => { return a.order- b.order})
      setLocations([...newLocations])
    }
  }

  function deleteLocation(i) {
    let newLocations= locations
    newLocations.splice(i, 1)
    setLocations([...newLocations])
    setDeleteRow()
  }

  function addLocation(i) {
    let newLocations= locations
    newLocations.splice(i, 0, {_id: null, order: 0, value: "nytt", fi: "-", sv: "-", price: "0.00", show_price: 0})
    setLocations([...newLocations])
  }

  return (
    <ComponentContainer header="Upphämntings-/Sjösättnings platser" disablebuttons={true}>
        "Value" är värdet som sparas, det är viktigt att det är riktgivande och unikt.
          "Value" får ej ha mellanslag eller speciella bokstäver.
          Tar man bort en plats så faller det automatiskt bort ifrån aktiva offertförfrågningar.
           Finska/Svenska kolumnerna är vad som syns på formuläret. 
          Ordningen är den samma som i formuläret, och går att byta med att dra på raderna.
          "Value:other" måste vara sist i listan.
        <div className="UsersContainer">
          <div className="UsersContainerTableBody">
            <div className="UsersContainerTableRow">
              {locations[0]&&Object.keys(locations[0]).map(key => { if (key!== '_id'&&key!=='order') return (
                <div key={key} className="UsersContainerTableCell">
                  <div>{fieldHeader(key)}</div>
                </div>
              )})}
              <div className="UsersContainerTableCell">
                <div>Alternativ</div>
              </div>
            </div>
            {locations?.map((location, i) => { return ( 
              <div 
                draggable="true"
                id={i}
                key={i} 
                onDragStart={handleDragStart}
                onDragOver={handleDragOver}
                onDragEnd={handleDragEnd}
                className="UsersContainerTableRow"> 
                  {Object.keys(location).map(key => { if (key!== '_id'&& key!== 'order') return ( // remove _id and order from rendering
                    <div key={key} className="PickupLocationsTableCell">
                      {key!=='show_price'?
                        <div 
                          id={i}
                          spellCheck="false"
                          contentEditable="true" 
                          onInput={(e)=>handleChange(e, i, key)} 
                          suppressContentEditableWarning={true}>
                            {location[key]}
                        </div>
                          :
                        <div id={i} onClick={()=>{handleShowPriceChange(i)}}>{location[key]=== 1? "Ja": "Nej"}
                        </div>}
                    </div>
                  )})}
                  <div id={i} className="PickupLocationsTableCell">
                    <div className="PickupLocationsOptions">
                      {deleteRow!==i?
                        <>
                          <p onClick={()=>setDeleteRow(i)}>
                            Radera/
                          </p>
                          <p onClick={()=>addLocation(i)}>
                            Lägg till
                          </p>
                        </>
                      :
                        <p onClick={()=> deleteLocation(deleteRow)}>
                          Säker?
                        </p>
                      }
                    </div>
                  </div>
              </div>
            )})}
          </div>
        </div>
        <form onSubmit={savePickupLocations}>
          {responseMessage&&<p>{responseMessage}</p>}
          <input type="submit" className="PasswordSaveButton" value="SPARA"/>
        </form>
    </ComponentContainer>
  )
}

export default forwardRef(PickupLocations)