import React, {useState, useEffect}  from 'react'
import ComponentContainer from './ComponentContainer'

function Report(props) {
  const {socket, response, setMessage} = props
  const [report, setReport] = useState('')

  function submit(e) {
    e.preventDefault()
    socket.send(JSON.stringify({
      "action": "save_report",
      "report": report
    }))
  }

  useEffect(()=> {
    if (response?.save_report) {
      if (response.save_report==='success') {
        setMessage({type: "success", text: "Tack, rapporten är sparad", onOK: ()=> setMessage(false)})
      } else {
        setMessage({type: "error", text: response.save_report, onOK: ()=> setMessage(false)})
      }
    }
  }, [response])

  return ( 
    <ComponentContainer disablebuttons={true} header="Gör rapport" maxwidth="800px">
      <form onSubmit={submit} style={{display:"flex", flexDirection:"column", alignItems:"flex-start"}}>
        <div>{"Här kan du göra en valfri anonym rapport om förändrings önskemål eller om du hittat \"buggar\", eller annan feedback."}</div>
        <div>{"Om du inte vill vara anonym så skriv fast h <ditt namn> i slutet."}</div>
        <textarea style={{ outline:"none", resize: "none", height: "200px", width:"100%"}}value={report} onChange={(e)=>setReport(e.target.value)}/>
        <input type="submit" className="PasswordSaveButton" value="SKICKA"/>
      </form>
    </ComponentContainer>
  )
}

export default Report