import React, { useEffect, useState } from 'react'
import { useHistory } from "react-router-dom";
import Notification from './Notification'
import ComponentContainer from './ComponentContainer'
import ScrollToTop from './ScrollToTop'
import './AccountChangePassword.css'

export const AccountChangePassword= ((props) => {
  const {socket, response, setResponse, user, setUser, setMessage} = props;
  const history = useHistory();
  const [form, setForm] = useState({
    oldPassword:'', 
    newPassword: '', 
    verifyPassword: ''
  })

  const [formError, setFormError] = useState({
    oldPassword:'', 
    newPassword: '', 
    verifyPassword: ''
  })

  function sendPassword(e) {
    let error= false
    e.preventDefault()
    if (form.oldPassword.length<6&&user.firstlogin===false) {
      setFormError(prevState => ({...prevState, oldPassword: 'Lösenordet för kort'}))
      error= true
    }
    if (form.newPassword.length<6) {
      setFormError(prevState => ({...prevState, newPassword: 'Lösenordet för kort'}))
      error= true
    }
    if (form.newPassword!==form.verifyPassword&&!error) {
      setFormError(prevState => ({...prevState, verifyPassword: 'Lösenorden matchar inte'}))
      error= true
    }
    if (error) return;
    socket.send(JSON.stringify({
      "action": "updatePassword", 
      "newPassword": form.newPassword,
      "oldPassword": form.oldPassword
    }));
  }
  useEffect(()=> {
    if (response?.updatePassword==="fail!") {
      if (response.reason==="no match") {
        setFormError(prevState => ({...prevState, oldPassword: 'Fel lösenord'}))
      } else if (response.reason==="new same as old") {
        setFormError(prevState => ({...prevState, newPassword: 'Nya samma som gamla'}))
      }
    } else if (response?.updatePassword==="success") {
      setMessage({text: user.lang==='fi'?"Salasana päivitetty!":"Lösenordet uppdaterat!", onOK:()=> {
        setMessage({text: ''})
        history.push('/')
      }})
      setUser(prevState => ({...prevState, firstlogin: false})) 
    }
  }, [response])

  useEffect(()=> {
  }, [])

  useEffect(()=> {
    ScrollToTop()
  }, [])
 
  return (
    <ComponentContainer disablebuttons={true} maxwidth={480} header={user.firstlogin=== false? user.lang==='fi'?"Vaihda salasana":"Byt lösenord": user.lang==='fi'?"Uusi salasana vaaditaan:":"Nytt lösenord krävs:"}>
      <form className="ChangePasswordForm" onSubmit={(e)=>sendPassword(e)}>
        <div className="ChangePasswordContainer">
          {user.firstlogin=== false&&
          <>
            <p>{user.lang==='fi'?"Nykyinen salasana":"Nuvarande lösenord"}:</p>
            {formError?.oldPassword?<div style={{position: 'relative'}}><div className="PasswordError"><p>{formError.oldPassword}</p></div></div>:null}
            <input 
              type="password" 
              name= "oldPassword"
              onFocus={()=> setFormError(prevState => ({...prevState, oldPassword: ''}))}
              value={form.oldPassword} 
              onChange={e=> setForm(prevState => ({...prevState, oldPassword: e.target.value}))} />
          </>
          }
          <p>{user.lang==='fi'?"Uusi salasana":"Nytt lösenord"}:</p>
          {formError?.newPassword?<div style={{position: 'relative'}}><div className="PasswordError"><p>{formError.newPassword}</p></div></div>:null}
          <input 
            type="password" 
            name="newPassword"
            onFocus={()=> setFormError(prevState => ({...prevState, newPassword: ''}))}
            value={form.newPassword} 
            onChange={e=> setForm(prevState => ({...prevState, newPassword: e.target.value}))}/>
          <p>{user.lang==='fi'?"Varmista uusi salasana":"Verifiera nytt lösenord"}:</p>
          {formError?.verifyPassword?<div style={{position: 'relative'}}><div className="PasswordError"><p>{formError.verifyPassword}</p></div></div>:null}
          <input 
            type="password" 
            name="verifyPassword"
            onFocus={()=> setFormError(prevState => ({...prevState, verifyPassword: ''}))}
            value={form.verifyPassword} 
            onChange={e=> setForm(prevState => ({...prevState, verifyPassword: e.target.value}))}/>
          <div className="PasswordButtonContainer">
            <input
              type="submit" 
              value="SPARA" 
              className="PasswordSaveButton"/> 
          </div>
        </div>
      </form>
    </ComponentContainer>
  )
})


export default AccountChangePassword;