import React, {useEffect, useState} from 'react'
import './Message.css'

function Message({text, type, onOK, onNO, lang}) {
  const [open, setOpen] = useState(false)

  const types = {
    warning: {
      backgroundColor: "#f2f25a", 
      text: {
        fi: "VAROITUS!", 
        sv: "VARNING!"}
    },
    error: {backgroundColor: "#e23636", text: {fi: "VIRHE!", sv: "FEL!"}},
    normal: {backgroundColor: "#ffffff", text: {fi: "Viesti", sv: "Meddelande"}},
    success: {backgroundColor: "#5cd65c", text: {fi: "Onnistui", sv: "Lyckades"}}
  }

  useEffect(() =>{
    let prev = document.body.style.overflow
    document.body.style.overflow = "hidden"
    setOpen(true)
    return (()=> {
      document.body.style.overflow = prev
    })
  }, [])

  function click(action)  {
    setOpen(false)
    setTimeout(action, 70)
  }

  useEffect(()=> {
    if (text!=='') setOpen(true)
  }, [text])

  return (
    <div className="GeneralMessageContainer">
      <div className={open?"GeneralMessageAnimate GeneralMessageShow":"GeneralMessageAnimate"}>
        <div className="GeneralMessage">
          <div className="GeneralMessageUpper" style={{backgroundColor: types[type]?types[type].backgroundColor:types.normal.backgroundColor}}>
            {lang&&type?
              types[type].text[lang]:
              type&&!lang?
              types[type].text.sv:
              lang&&!type?
              types.normal.text[lang]:
              types.normal.text.sv}
            {/*type==='warning'?lang==='fi'?"VAROITUS":"VARNING":type==='error'?"ERROR":lang==='fi'?"Viesti":"Meddelande" EDITED 4.8.2021*/}
          </div>
          <div className="GeneralMessageLower">
            <div className="GeneralMessageText">{text}</div>
            <div className="GeneralMessagesButtons">
              {onNO&&<div
                className="PasswordSaveButton GeneralMessageButton"
                onClick={()=>{click(onNO)}}>
                  {lang==='fi'?"Ei":"Nej"}
              </div>}
              <div 
                className="PasswordSaveButton GeneralMessageButton"
                onClick={()=>{click(onOK)}}>
                  {onNO?lang==='fi'?"Kyllä":"Ja":"OK"}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Message