import React, { useEffect, useState, createContext } from 'react';

export const DimensionContext = createContext();

export const WithDimensions = props => {
  const [screenHeight, setScreenHeight]= useState(window.innerHeight?window.innerHeight:0)
  const [screenWidth,  setScreenWidth] = useState(window.innerWidth?window.innerWidth:0)
  
  function UpdateScreenSize() { 
    setScreenHeight(window.innerHeight)
    setScreenWidth(window.innerWidth)
  } 
   
  const hasWindow = typeof window !== 'undefined';
  
  useEffect(() => {
    window.addEventListener("resize", UpdateScreenSize)
    return (() => {
      window.removeEventListener("resize", UpdateScreenSize)
    })
  }, [hasWindow])

  return (
    <DimensionContext.Provider value={{screenHeight, screenWidth}}>
      {props.children}
    </DimensionContext.Provider> 
  )
}

