import React, { useEffect, useState, useRef } from 'react'
import { useLocation } from "react-router-dom"
import Calendar from 'react-calendar';
import WinterStorageChart1 from './WinterStorageChart1'
import moment from 'moment'
import WinterstorageCalc from './WinterstorageCalc'

import ComponentContainer from './ComponentContainer'

import './WinterstorageRequests.css'

function WinterstorageRequests(props) {
  const [value, onCalChange] = useState(new Date());

  const location = useLocation()
  const {user, socket, response, setMessage, setLoading, setSilentMSG, search, setSearch} = props
  const [toggle, setToggle] = useState({
    selectedUser: -1,
    showUser: false
  })

  const [showAcceptedOffers, setShowAcceptedOffers] = useState(true)
  const [showOpenOffers, setShowOpenOffers] = useState(true)
  const [calendarEvents, setCalendarEvents] = useState()
  const [openCalendarDay, setOpenCalendarDay] = useState(false)

  const [calcPrices, setCalcPrices] = useState()

  const [priceSetBy, setPriceSetBy]= useState("")
  const [informedBy, setInformedBy]= useState("")

  const oldPriceRef = useRef()
  const xRef = useRef() // mouse cordinates for calendar
  const yRef = useRef()

  function OutsideClick(ref){ // this is for the Calendar...
    useEffect(()=>{
      function handleClickOutside(e) {
        if (ref.current && !ref.current.contains(e.target)) {
          setOpenCalendarDay(false) // clock outside..
        } else {
          xRef.current  = e.clientX
          yRef.current  = e.clientY
        }  
      }
      document.addEventListener("mousedown", handleClickOutside)
      return () => {
        document.removeEventListener("mousedown", handleClickOutside)
      }  
    }, [ref])
  }


  const wrapperRef = useRef(null)
  
  OutsideClick(wrapperRef)
  const [selectedUserForm, setSelectedUserForm] = useState({})
  const [table, setTable]= useState([]);
  const fields = [
    "_id",
    "customer_name",
    "price", 
    "Status",
    "customer_informed_by",
    "created_time"
  ]

  const customerFields = [
    "customer_name",
    "customer_email" ,
    "customer_phone",
    "lang",
    "already_customer",
    "customer_has_useraccount"
  ]

  const offerFields = [
    "customer_informed",
    "customer_accepted_offer",
    "customer_viewed_offer",
    "created_time",
    "price_set_by_id",
    "customer_informed_by_id",
  ]

  const boatFields = [
    "boat",
    "boat_register",
    "boat_length",
    "boat_width",
  ]

  const engineFields = [
    "engine",
    "engine_amount",
    "engine_cycle",
    "engine_hp",
    "engine_internal"
  ]

  const storageFields = [
    "pickup_datetime",
    "launch_datetime",
    "pickup_and_launch",
    "pickup_location",
    "pickup_other",
    "winterstorage_type",
    "discount"
  ]

  const [orderBy, setOrderBy] = useState('created_time')
  const [order, setOrder]     = useState('DESC')

  useEffect(()=> {
    if (location.search.split('?id=')[1]) {
      getForm(location.search.split('?id=')[1])
    } else {
      getForms()
    }
  }, [])

  const [availableLocations, setAvailableLocations]= useState({})
  const [availableServices, setAvailableServices]= useState([])

  useEffect(()=>{ // CORRECTIONS
    if (selectedUserForm._id!== undefined&& Object.keys(availableLocations).length> 0&& Object.keys(availableServices).length> 0) {
      let newUserForm = selectedUserForm

      let services= Object.keys(newUserForm.form)
      availableServices.forEach(e => {
        if (!services.includes(e.name)) newUserForm.form[e.name]= "no"
      })
      if (newUserForm.customer_commented) newUserForm.comment_to_customer= newUserForm.comment_to_customer+ "\nKundens begäran/kommentar:\n"+ newUserForm.customer_commented
      if (newUserForm.form.engine_internal==="true"||newUserForm.form.engine_internal===true) newUserForm.form.engine_internal="yes"
      if (newUserForm.form.engine_internal===undefined||newUserForm.form.engine_internal===false||newUserForm.form.engine_internal==="false") newUserForm.form.engine_internal="no"
      if (newUserForm.form.pickup_and_launch===undefined) newUserForm.form.pickup_and_launch="no"
      if (newUserForm.form.pickup_location!==undefined&&newUserForm.form.pickup_location!=='other') newUserForm.form.pickup_other= undefined
      if (newUserForm.form.already_customer===true) {newUserForm.form.already_customer= 'yes'} else {newUserForm.form.already_customer= 'no'}
      if (newUserForm.form.pickup_and_launch==="yes"&& newUserForm.form.pickup_location===undefined) {
        newUserForm.form.pickup_location=availableLocations[0].value
      } 
      if (newUserForm.form.pickup_and_launch==="no") newUserForm.form.pickup_location=availableLocations[0].value // just store a value so its able to add locations if customer wants
      if (newUserForm.price_set_by_id!== 0) getUserNameByID(newUserForm.price_set_by_id, "price_set_by_id")
      if (newUserForm.customer_informed_by_id!== 0) getUserNameByID(newUserForm.customer_informed_by_id, "customer_informed_by_id")
      setSelectedUserForm(prevState => ({...prevState, ...newUserForm}))
      checkDateFieldErrors("pickup_datetime")
      checkDateFieldErrors("launch_datetime")
    }
  }, [selectedUserForm?._id, Object.keys(availableLocations).length, Object.keys(availableServices).length])

  useEffect(()=> {
    if (selectedUserForm._id) {
      socket.send(JSON.stringify({
        "action": "get_calendar_events",
        "exclude": selectedUserForm._id
      }))
    }
  }, [selectedUserForm?._id])

  useEffect(()=> {
    if (response?.get_calendar_events) {
      //format
      response.get_calendar_events.map((field, i) => {
        field.launch_datetime = JSON.parse(field.launch_datetime)
        field.pickup_and_launch = JSON.parse(field.pickup_and_launch)
        field.pickup_datetime = JSON.parse(field.pickup_datetime)
        field.pickup_location = JSON.parse(field.pickup_location)
        field.pickup_other = JSON.parse(field.pickup_other)
        field.type = JSON.parse(field.type)
      })
      setCalendarEvents(response.get_calendar_events)
    } else
    if (response?.get_requestform) { 
      response.get_requestform.form= JSON.parse(response.get_requestform.form)
      setToggle(prevState => ({...prevState, showUser: !toggle.showUser}));
      setSelectedUserForm(response.get_requestform)
    } else
    if (response?.get_requestforms) {
      setTable(response.get_requestforms)
      getAvailableServicesAndLocations()
    } else 
    if (response?.get_availableservicesandlocations) {
      setAvailableServices(response.get_availableservicesandlocations.services)
      setAvailableLocations(response.get_availableservicesandlocations.locations)
      setLoading(false)
      getCalcPrices()
    } else 
    if (response?.get_calculator_prices) {
      setCalcPrices(response.get_calculator_prices)
    } else
    if (response?.save_form) {
      if (response.save_form=== 'success') {
        setSilentMSG({text: response.save_form_message, type:"success", onOK: () => {setSilentMSG(false)}})
        setToggle(prevState=> ({...prevState, showUser: false, selectedUser: -1}))
        socket.send(JSON.stringify({imEditing: false}))
        setSelectedUserForm([])
        getForms()
        setFormError({})
      }
      if (response.save_form=== 'error') {
        if (response.save_form_ask) {
          setMessage({
            text: response.save_form_message, 
            type: 'warning',
            onNO: () => {setMessage({text: ''})},
            onOK: () => {
              setMessage({text: ''});
              let object= selectedUserForm
              setLoading(true)
              response.save_form_resend?
                socket.send(JSON.stringify({
                  "action": "save_form",
                  "action2": "send_email",
                  "object": object,
                  "force": true
                })) :
                socket.send(JSON.stringify({
                  "action": "save_form",
                  "object": object,
                  "force": true
                }))
            }
          })
        } else {
          setMessage({
            text: response.save_form_message, 
            type: 'error', 
            onOK: () => {setMessage({text: ''})}
          })
        }
        setLoading(false)
      }
    } else if (response?.get_user_name_by_id) {
      const name = (response.get_user_name_by_id.first_name+ " "+ response.get_user_name_by_id.last_name).replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())
      if (response.for_field==="price_set_by_id") {setPriceSetBy(name)} else
        if (response.for_field==="customer_informed_by_id") {setInformedBy(name)}
    } else if (response?.delete_form) {
      if (response.delete_form=== 'success') {
        setSilentMSG({text: "Offertförfrågan borttagen", type: "success", onOK: () => {
          setSilentMSG(false)
          setToggle({selectedUser: -1,showUser: false})
          socket.send(JSON.stringify({imEditing: false}))
        }}) 
      } else {setSilentMSG({text: "Något gick fel!", type: "error", onOK: () => {
        setSilentMSG(false)
      }})}
    }
  }, [response]) 

  function getUserNameByID(id, field) {
    socket.send(JSON.stringify({
      "action"    : "get_user_name_by_id",
      "id"        : id,
      "for_field" : field
    }))
  }

  function getCalcPrices() {
    socket.send(JSON.stringify({
      "action": "get_calculator_prices",
    }))
  }

  useEffect(()=> {
    if (Object.keys(selectedUserForm).length===0) {
      getForms()
    }
  }, [search])

  const pageRef = useRef(25) // items per page
  const currentPageRef = useRef(1) // current page
  
  const totalCustomers = useRef(JSON.parse(localStorage?.getItem('stats'))?.totalForms[0]["COUNT(_id)"])

  

  function getForms() {
    const sendfields= '`_id`, `customer_name`, `price`, `customer_informed`, `customer_informed_by_id`,`customer_viewed_offer`,`customer_accepted_offer`,`created_time`'
    setLoading(true)
    socket.send(JSON.stringify({
      "action": "get_requestforms",
      "fields": sendfields,
      "offset": pageRef.current* (currentPageRef.current- 1),
      "limit": pageRef.current,
      "search": search,
      "order_by": orderBy,
      "order": order
    }))
  }

  function getForm(id) {
    socket.send(JSON.stringify({
      "action": "get_requestform",
      "id": id
    }))
  }
  

  function getAvailableServicesAndLocations() {
    setLoading(true)
    socket.send(JSON.stringify({
      "action": "get_availableservicesandlocations"
    }))
  }

  function fieldHeader(h) {
    if (h==="_id") {return "#"} else
    if (h==="customer_informed_by") {return "Offert av"} else
    if (h==="customer_name") {return "Namn"} else
    if (h==="customer_has_useraccount") {return "Har användarkonto"} else
    if (h==="engine_internal") {return "Innombordare"} else
    if (h==="customer_email") {return "E-post"} else
    if (h==="customer_phone") {return "Mobil"} else
    if (h==="price") {return "Pris"} else
    if (h==="customer_accepted_offer") {return "Accepterat"} else
    if (h==="customer_viewed_offer") {return "Öppnat"} else
    if (h==="created_time") {return "Uppdaterad"} else
    if (h==="customer_informed") {return "Fått svar"} else
    if (h==="price_set_by_id") {return "Priset bestämt av"} else
    if (h==="customer_informed_by_id") {return "Kunden kontaktad av"} else
    if (h==="comment_to_customer") {return "Kommentar till kunden"} else
    if (h==="lang") {return "Språk"} else
    if (h==="boat_width") {return "Båt bredd"} else
    if (h==="boat_length") {return "Båt längd"} else
    if (h==="engine_amount") {return "Motormängd"} else
    if (h==="discount") {return "Rabattkod"} else
    if (h==="boat_register") {return "Båt register"} else
    if (h==="engine_cycle") {return "Motorns taktmängd"} else
    if (h==="boat_other_services") {return "Annan service"} else
    if (h==="pickup_datetime") {return "Upptagning"} else
    if (h==="launch_datetime") {return "Sjösättning"} else
    if (h==="boat") {return "Båt"} else
    if (h==="already_customer") {return "Redan kund"} else
    if (h==="waxing") {return "Vaxning"} else
    if (h==="antifouling_overpaint") {return "Påmålning av bottenfärg"} else
    if (h==="winterstorage_type") {return "Förvaringens typ"} else
    if (h==="pickup_and_launch") {return "Upptagning/sjösättning"} else
    if (h==="cleaning") {return "Städning"} else
    if (h==="engine_hp") {return "Motoreffekt (hk)"} else
    if (h==="engine") {return "Motor"} else
    if (h==="engine_seasonal_maintenance") {return "Motorns säsongsservice"} else
    if (h==="pickup_location") {return "Upptagning/sjösättning plats"} else
    if (h==="pickup_other") {return "Annan plats"} else
    return h
  }

  function formatFieldValue(f, key) {
    if (key==="customer_has_useraccount") {return f===0||f===null?"Nej":"Ja"} else
    if (key==="customer_name") {return f.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())} else
    if (key==="customer_accepted_offer") {return f===0?"Nej":"Ja"} else
    if (key==="customer_viewed_offer") {return f===0?"Nej":"Ja"} else
    if (key==="created_time") {return f.split('T')[0]} else
    if (key==="customer_informed") {return f===0||f===''?"Nej":"Ja"} else
    if (key==="price_set_by_id") {return f===0||f===''||f===null?"Ingen":priceSetBy} else
    if (key==="customer_informed_by_id") {return f===0||f===''||f===null?"Ingen":informedBy} else
    if (key==="comment_to_customer") {return f===null||f===''||f===0?"Ingen":f} else
    if (key==="comment_to_others") {return f===null||f===''||f===0?"":f} else
    if (key==="engine_internal") {return f===true||f==='yes'?"Ja":"Nej"} else
    if (f==="yes") {return "Ja"} else
    if (f==="no") {return "Nej"} else 
    if (f==="sv") {return "Svenska"} else
    if (f==="fi") {return "Finska"} else
    if (f==="warm") {return "Varm"} else
    if (f==="cold") {return "Kall"} else
    if (key==="engine_cycle") {return f==="4-stroke"?"4 takt":"2 takt"} else
    if (key==="already_customer") {return f===true?"Ja":"Nej"} else
    if (key==="pickup_location"&&f!==undefined) {if ((Object.keys(availableLocations).length> 0)) {
      return (availableLocations?.filter(l => f===l.value)[0]?.sv)}
    } else 
    return f
  }

  function handleSelectedUserFormChange(e, i, key) {
    let newValues= selectedUserForm
    newValues.form[key]= e.target.innerHTML
    setSelectedUserForm({}) // cursor otherwise jumps to start
    setSelectedUserForm(newValues)
  }

  useEffect(()=> {
    getForms()
  }, [order, orderBy])

  function handleSelectedUserChange(e, i, key) {
    let newValues= selectedUserForm
    newValues[key]= e.target.innerHTML
    setSelectedUserForm({}) // cursor otherwise jumps to start
    setSelectedUserForm(newValues)
  }

  function saveChanges(e) {
    e.preventDefault()
    /*if (Object.keys(formError).length>0) {
      setMessage({text: "Formuläret har fel!", type: "error"})
      return
    }*/
    let object= selectedUserForm
    if (oldPriceRef.current!== object.price) object.price_set_by_id= user.id
    setLoading(true)
    socket.send(JSON.stringify({
      "action": "save_form",
      "object": object
    }))
  }

  function saveAndSend(e) {
    e.preventDefault()
    /*if (Object.keys(formError).length>0) {
      setMessage({text: "Formuläret har fel!", type: "error"})
      return
    }*/
    if (selectedUserForm.price==="0.00") {
      setMessage({text: "Måst ha ett pris! Inte sparat eller skickat.", type: "error", onOK:()=>setMessage({text: ""})})
      return
    }
    let object= selectedUserForm
    if (oldPriceRef.current!== object.price) object.price_set_by_id= user.id
    setLoading(true)
    socket.send(JSON.stringify({
      "action": "save_form",
      "action2": "send_email",
      "object": object
    }))
  }

  function deleteRequestForm(e) { // beautiful callback isnt it :D
    e.preventDefault()
    setMessage({text:"Offertförfrågan kommer att försvinna från databasen. Försäljningen hålls kvar om den är gjord.\n\rVill du säkert ta bort?", type:"warning", onOK: () => {
      socket.send(JSON.stringify({
        "action": "delete_form",
        "id": selectedUserForm._id
      }))
      setMessage(false)
    }, onNO: () => {setMessage(false)}})
  }

  const [formError, setFormError]= useState({})

  function checkDateFieldErrors(key) {
    if (selectedUserForm.form[key]!==''&&selectedUserForm.form[key]!==undefined)
      try {
        let fieldToCheck= selectedUserForm.form[key]
        let dateToCheck= fieldToCheck.split(' ')[0]
        let timeToCheck= fieldToCheck.split(' ')[1]
        let time1= timeToCheck.split(':')[0]
        let time2= timeToCheck.split(':')[1]
        if (isNaN(time1)|| isNaN(time2)) throw 'err'
        if (time1.length>2||time1>24) throw 'err'
        if (time2.length>2||time2>24) throw 'err'
        let date1= dateToCheck.split('.')[0]// day
        let date2= ((dateToCheck.split('.')[1])- 1)// month
        let date3= dateToCheck.split('.')[2]// year
        if (date1> 31|| date1< 0|| date2<0|| date2>= 12|| date3.length!==4) throw 'err'
        if (isNaN(date1)|| isNaN(date2)|| isNaN(date3)) throw 'err'
        let d= new Date(date3, date2, date1, time1, time2)
        if (isNaN(d.getTime())) throw 'err'
      } catch (e) {
        setFormError(prevState=> ({...prevState, [key]: "[dd.mm.yyyy hh:mm]"}))
        return true
      }
  }
  //user["customer_informed"], user["customer_accepted_offer"], user["customer_viewed_offer"], user["customer_informed_by_id"]
  function formatStatus(...args) {
    if (args[1]) {return "Accepterat offert"} else
    if (args[3]&&!args[0]) {return "Förändringsbegäran,r"} else
    if (args[0]&&args[2]&&args[3]) {return "Sett offerten,w"} else 
    if (args[0]) {return "Inte öppnat offerten,y"} else
      return "Väntar på offert,r"
  }

  return (
    <>
      <ComponentContainer header="Stats" maxwidth="1100px">
        <WinterStorageChart1 {...props}/>
      </ComponentContainer>
      {!toggle.showUser? 
        <ComponentContainer header="Offertförfrågningar" maxwidth="1100px" disablebuttons={true}>
          <div className="UsersPages">
            <span className="relative">
            </span>
            <span>
              <span className="noselect">
                <span className="cursorpointer" onClick={()=>{
                  if (currentPageRef.current>1) {currentPageRef.current-= 1; getForms()}
                }}>{"<"}</span> Sida 
                <span>{currentPageRef.current}/{Math.ceil((totalCustomers.current)/ pageRef.current)===0?1:Math.ceil((totalCustomers.current)/ pageRef.current)} </span>
                <span className="cursorpointer" onClick={()=> {
                  if (currentPageRef.current<Math.ceil((totalCustomers.current)/ pageRef.current)) {currentPageRef.current+= 1; getForms()}
                  }}
                >{">"}
                </span>
              </span> 
            </span>
          </div>
            <div className="UsersContainer">
              <div className="UsersContainerTableBody">
                <div className="UsersContainerTableRow">
                  {fields.map((field,i)=> { return (
                    <div key={i} className="UsersContainerTableCell" style={{cursor: "pointer"}} onClick={()=> {
                      if (orderBy=== field) {
                        if (order=== 'DESC') {
                          setOrder('ASC')
                        } else {
                          setOrder('DESC')
                        }
                      } else {
                        setOrderBy(field)
                        setOrder('DESC')
                      }
                    }}>
                      <div>{fieldHeader(field)}{orderBy===field?order==='DESC'?" ^":" \u02C5":""}</div>
                    </div>
                  )})}
                </div>
                {table?.map((user, i)=> { return (
                  <div 
                      key={i} 
                      className="UsersContainerTableRow" 
                      onClick={()=>{
                        setToggle(prevState => ({...prevState, selectedUser: -1}));
                        socket.send(JSON.stringify({imEditing: {table: "winterstorage_request_forms", id: table[i]._id}}))
                        getForm(table[i]._id)
                        //setSelectedUserForm(table[i])
                        }}>
                    {fields.map((field, i) => { if (field!=='Status') {return (
                      <div key={i} className="UsersContainerTableCell">
                        <div>{formatFieldValue(user[field], field)}</div>
                      </div>
                    )}else if (field!=='_id'){
                      let color
                      let def = formatStatus(
                        user["customer_informed"], 
                        user["customer_accepted_offer"], 
                        user["customer_viewed_offer"], 
                        user["customer_informed_by_id"]).split(',')[1]
                      if (def==='y') {color = '#ffff80'} else
                      if (def==='r') {color = '#ffb3b3'} else 
                      if (def==='w') color = 'transparent'
                      return (
                      <div key={i} className="UsersContainerTableCell">
                        <div style={{backgroundColor: color}}>{formatStatus(
                          user["customer_informed"], 
                          user["customer_accepted_offer"], 
                          user["customer_viewed_offer"], 
                          user["customer_informed_by_id"]).split(',')[0]
                        }</div>
                      </div>
                    )}})}
                  </div>
                )})}
              </div>
            </div>
          </ComponentContainer>
        : // OPEN USER
        <ComponentContainer maxwidth="1100px"
          header={selectedUserForm.customer_name.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())}
          onClose={()=> {
            setToggle(prevState=> ({...prevState, showUser: false, selectedUser: -1}))
            socket.send(JSON.stringify({imEditing: false}))
            setSelectedUserForm({})
            getForms()
            setFormError({})
          }}>
            <div className="WinterStorageRealContainer">
              <span>
                Gula fälten är redigerbara, och blåa växlar värde med <span style={{textDecoration: 'underline'}}>dubbelklick</span>.
              Rekommenderar att ändra namnens ordning ifall de är fel. För- och sen efternamn.
              Appen lagrar information i databasen när ni skickar offert så det underlättar att det inte förekommer tex dubletter. Båtens mått i meter.
              OBS! Om du godkänner till försäljning så bör upptagning/sjösättning datumen finnas, dom kan ändras efteråt.
              </span>
            <div className="WinterStorageFormContainer">
              <div>
                <h4>Kunduppgifter</h4>
                {customerFields.map((field, i) => { if (field!=='lang'&&field!=='already_customer') { return (
                  <div key={i} className="WinterStorageFormField">
                    <div>
                      {fieldHeader(field)+": "}
                    </div>
                    <div
                        spellCheck="false"
                        contentEditable={field==="customer_name"||field==="customer_email"||field==="customer_phone"?"true":"false"}
                        onInput={(e)=> handleSelectedUserChange(e, toggle.selectedUser, field)} 
                        suppressContentEditableWarning={true}
                        id={i}
                        className="WinterStorageFieldValue"
                        >
                      {formatFieldValue(selectedUserForm[field], field)}
                    </div>
                  </div>
                )} else if (field==='lang') {return (
                  <div key={i} className="WinterStorageFormField">
                  <div>
                    {fieldHeader(field)+": "}
                  </div>
                  <div className="WinterStorageFieldClickable" onDoubleClick={()=> {
                      let newForm = selectedUserForm.form
                      newForm.lang= newForm.lang==='fi'?'sv':'fi'
                      setSelectedUserForm(prevState => ({...prevState, form: newForm}))}}>
                    {formatFieldValue(selectedUserForm.form.lang, field)}
                  </div>
                </div>
                )} else return (
                  <div key={i} className="WinterStorageFormField">
                  <div>
                    {fieldHeader(field)+": "}
                  </div>
                  <div className="WinterStorageFieldClickable" onDoubleClick={()=> {
                      let newForm = selectedUserForm.form
                      newForm.already_customer= newForm.already_customer==='yes'?'no':'yes'
                      setSelectedUserForm(prevState => ({...prevState, form: newForm}))}}>
                    {formatFieldValue(selectedUserForm.form.already_customer, field)}
                  </div>
                </div>
                )})}
                <h4 style={{paddingTop:"20px"}}>Offertstatus</h4>
                {offerFields.map((field, i) => { return (
                  <div key={i} className="WinterStorageFormField">
                    <div>
                      {fieldHeader(field)+": "}
                    </div>
                    <div
                        spellCheck="false"
                        contentEditable={field==="price"?"true":"false"}
                        onInput={(e)=> handleSelectedUserChange(e, toggle.selectedUser, field)} 
                        suppressContentEditableWarning={true}
                        id={i}
                        className="WinterStorageFieldValue"
                        >
                      {formatFieldValue(selectedUserForm[field], field)}
                    </div>
                  </div>
                )})}
              </div>
              <div>
                <h4>Båtuppgifter</h4>
                {Object.keys(selectedUserForm.form).map((key, i)=> { 
                  if (boatFields.includes(key)) {return (
                    (selectedUserForm.form[key]!=='no')&&(selectedUserForm.form[key]!=='')&&
                    <div key={i} className="WinterStorageFormField">
                      <div>
                        {fieldHeader(key) + ": "}
                      </div>
                      <div spellCheck="false"
                          contentEditable={"true"}
                          onInput={(e)=> handleSelectedUserFormChange(e, toggle.selectedUser, key)} 
                          suppressContentEditableWarning={true}
                          id={i}
                          className="WinterStorageFieldValue">
                        {formatFieldValue(selectedUserForm.form[key], key)}
                      </div>
                    </div>
                  )}})}
                <h4 style={{paddingTop:"20px"}}>Motoruppgifter</h4>
                {Object.keys(selectedUserForm.form).map((key, i)=> { 
                  if (engineFields.includes(key)) if (key!=="engine_cycle"&&key!=="engine_internal"){ return (
                    <div key= {i}>
                      {(selectedUserForm.form[key]!=='no')&&(selectedUserForm.form[key]!=='')&&
                        <div className="WinterStorageFormField">
                          <div>
                            {fieldHeader(key) + ": "}
                          </div>
                          <div
                              spellCheck="false"
                              contentEditable={"true"}
                              onInput={(e)=> handleSelectedUserFormChange(e, toggle.selectedUser, key)} 
                              suppressContentEditableWarning={true}
                              id={i}
                              className="WinterStorageFieldValue">
                            {formatFieldValue(selectedUserForm.form[key], key)}
                          </div>
                        </div>}
                    </div>
                )} else if (key==="engine_cycle") {return (
                  <div key={i}>
                  {(selectedUserForm.form[key]!=='no')&&(selectedUserForm.form[key]!=='')&&
                    <div className="WinterStorageFormField">
                      <div>
                        {fieldHeader(key) + ": "}
                      </div>
                      <div
                          onDoubleClick={(e) => {
                            let newForm = selectedUserForm.form
                            newForm.engine_cycle= newForm.engine_cycle==='2-stroke'?'4-stroke':'2-stroke'
                            setSelectedUserForm(prevState => ({...prevState, form: newForm}))}}
                          id={i}
                          className="WinterStorageFieldClickable">
                        {formatFieldValue(selectedUserForm.form[key], key)}
                      </div>
                    </div>}
                  </div>
                )} else return (
                  <div className="WinterStorageFormField">
                  <div>
                    {fieldHeader(key) + ": "}
                  </div>
                  <div
                      onDoubleClick={(e) => {
                        let newForm = selectedUserForm.form
                        newForm.engine_internal= newForm.engine_internal==='yes'||newForm.engine_internal===true?'no':'yes'
                        setSelectedUserForm(prevState => ({...prevState, form: newForm}))}}
                      id={i}
                      className="WinterStorageFieldClickable">
                    {formatFieldValue(selectedUserForm.form[key], key)}
                  </div>
                </div>
                )})}
                </div>
                <div>
                <h4>Vinterförvaring</h4>
                {storageFields.map((key, i)=> { if (selectedUserForm.form[key]!== undefined) return (
                    <div key={i}>
                      <div className="WinterStorageFormField">
                          {formError[key]&&
                            <div className="WinterStorageFieldErrorCont">
                              <div className="WinterStorageFieldError">
                                {formError[key]}
                              </div>
                            </div>}
                        <div>
                          {fieldHeader(key) + ": "}
                        </div>
                        <div 
                          onDoubleClick={()=> {
                            if (key=== "winterstorage_type") {
                              let newForm = selectedUserForm.form
                              newForm.winterstorage_type= newForm.winterstorage_type==='cold'?'warm':'cold'
                              setSelectedUserForm(prevState => ({...prevState, form: newForm}))
                            }
                            if (selectedUserForm.form[key]==="no"||selectedUserForm.form[key]==="yes") {
                              let newForm = selectedUserForm.form
                              newForm[key]= newForm[key]==='no'?'yes':'no'
                              setSelectedUserForm(prevState => ({...prevState, form: newForm}))
                            }
                            if (key=== "pickup_location") {
                              let newForm = selectedUserForm
                              let current = newForm.form.pickup_location
                              let locationValues = availableLocations.map(e => e.value)
                              let locationLen = locationValues.length- 1
                              locationValues.forEach((e, i) => {
                                if (e===current) {
                                  if (i!==locationLen) {
                                    newForm.form.pickup_location= locationValues[i+ 1]
                                    if (i+1 === locationLen) newForm.form.pickup_other= ""
                                  } else {
                                    newForm.form.pickup_location= locationValues[0]
                                    newForm.form.pickup_other= undefined
                                  }
                                }
                              })
                              setSelectedUserForm(prevState => ({...prevState, ...newForm}))
                            }
                          }}
                          onBlur={()=> {if (key==="pickup_datetime"||key==="launch_datetime") checkDateFieldErrors(key)}}
                          onFocus={()=> {if (formError[key]!== undefined) {setFormError(prevState=> ({...prevState, [key]: undefined}))}}}
                          spellCheck="false"
                          contentEditable={key==="pickup_datetime"||key==="launch_datetime"||key==="pickup_other"||key==="discount"?"true":"false"}
                          onInput={(e)=> handleSelectedUserFormChange(e, toggle.selectedUser, key)} 
                          suppressContentEditableWarning={true}
                          id={i}
                          className={
                            key==="winterstorage_type"||
                            key==="pickup_location"||
                            selectedUserForm.form[key]==="no"||
                            selectedUserForm.form[key]==="yes"?
                              "WinterStorageFieldClickable":
                              "WinterStorageFieldValue"}
                          >
                          {formatFieldValue(selectedUserForm.form[key], key)}
                        </div>
                      </div>
                    </div>
                )})}
              </div>
              <div>
              <h4>Tjänster</h4>
              {availableServices.map((key, i) => {return (
                <div key={i}>
                  <div className="WinterStorageFormField">
                    <div>
                      {key.sv+ ": "}
                    </div>
                    <div 
                      onDoubleClick={()=> {
                        if (selectedUserForm.form[key.name]==="no"||selectedUserForm.form[key.name]==="yes") {
                          let newForm = selectedUserForm.form
                          newForm[key.name]= newForm[key.name]==='no'?'yes':'no'
                          setSelectedUserForm(prevState => ({...prevState, form: newForm}))
                        }
                      }}
                      onInput={(e)=> handleSelectedUserFormChange(e, toggle.selectedUser, key)} 
                      id={i}
                      className={
                        selectedUserForm.form[key.name]==="no"||
                        selectedUserForm.form[key.name]==="yes"?
                          "WinterStorageFieldClickable":
                          "WinterStorageFieldValue"}>
                      {formatFieldValue(selectedUserForm.form[key.name], key)}
                    </div>
                  </div>
                </div>
                )})}
                <div>
                  <div className="WinterStorageFormField">
                    <div>
                      Annat:
                    </div>
                    <div 
                        spellCheck="false"
                        contentEditable="true"
                        suppressContentEditableWarning="true"
                        onInput={(e)=> handleSelectedUserFormChange(e, toggle.selectedUser, "boat_other_services")} 
                        id="boat_other_services"
                        className="WinterStorageFieldValue">
                      {selectedUserForm.form.boat_other_services||''}
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <h4>Kalender</h4>
                <div style={{textAlign: "center"}}>Upptagning/Sjösättning</div>
                <div>Visa:</div>
                <input id="showAcceptedOffers" checked={showAcceptedOffers} onChange={()=> setShowAcceptedOffers(!showAcceptedOffers)} type="checkbox"/>
                <label htmlFor="showAcceptedOffers">Accepterade offerter</label><br/>
                <input id="showOpenOffers" checked={showOpenOffers} onChange={()=> setShowOpenOffers(!showOpenOffers)} type="checkbox"/>
                <label htmlFor="showOpenOffers">Öppna förfrågningar</label>
                <Calendar
                  className="CalendarClass"
                  locale="sv-SV"
                  onChange={onCalChange}
                  value={value}
                  tileClassName={({ date, view }) => {
                    let YelEvents = []
                    let RedEvents = []
                    calendarEvents?.forEach(s => {
                      if (showAcceptedOffers&&s.customer_accepted_offer===1) {
                        RedEvents.push(s.pickup_datetime.split(' ')[0])
                        RedEvents.push(s.launch_datetime.split(' ')[0])
                      }
                      if (showOpenOffers&&s.customer_accepted_offer===0) {
                        YelEvents.push(s.pickup_datetime.split(' ')[0])
                        YelEvents.push(s.launch_datetime.split(' ')[0])
                      }
                    })
                    if (RedEvents.find(x=> x=== moment(date).format("D.M.YYYY"))){
                      return 'CalendarHighlightRed'
                    }
                    if (YelEvents.find(x=> x=== moment(date).format("D.M.YYYY"))){
                      return 'CalendarHighlightYellow'
                    }
                    return 'CalendarNormal'
                  }}
                  onClickDay={(value) => {
                    let eventsOnDay= []
                    calendarEvents?.forEach(s => {
                      // Safari formating...
                      let formatPickup = s.pickup_datetime.split(' ')[0]
                      formatPickup= formatPickup.split('.')[2] + '/' + (formatPickup.split('.')[1]) + '/' + formatPickup.split('.')[0]
                      let formatLaunch = s.launch_datetime.split(' ')[0]
                      formatLaunch= formatLaunch.split('.')[2] + '/' + (formatLaunch.split('.')[1]) + '/' + formatLaunch.split('.')[0]
                      if (new Date(formatPickup).getTime()===new Date(value).getTime()) eventsOnDay.push(s)
                      if (new Date(formatLaunch).getTime()===new Date(value).getTime()) eventsOnDay.push(s)
                    })
                    if (eventsOnDay.length>0) setOpenCalendarDay(eventsOnDay)
                  }}
                  />
                  {openCalendarDay&&
                    <div ref={wrapperRef} className="OpenCalendarDay" style={{borderRadius:"7px", borderTop:"15px solid rgb(127, 174, 212)", overflow: "auto", maxHeight: "320px", position:"fixed",left: (xRef.current-100)+"px", top: yRef.current+"px", backgroundColor: "#ddd", padding: "5px"}}>
                      {openCalendarDay.map((s, i) => 
                        <div key={i} style={{borderRadius:"5px", border: "1px solid #666", padding: "4px", marginTop:"5px", width:"100%", backgroundColor:"#fff"}}>
                          Offert: {s.customer_accepted_offer===1?"Godkänd":"Öppen"}<br/>
                          Förvaring: {s.type==="cold"?"Kall":"Varm"}<br/>
                          Kund: <br/>{s.customer_name}<br/>
                          Upptagning: <br/>{s.pickup_datetime}<br/>
                          Sjösättning: <br/>{s.launch_datetime}<br/>
                          Upptagning/Sjösättning: <br/>{s.pickup_location==="other"?s.pickup_other:s.pickup_location}<br/>
                        </div>)}
                  </div>}
                <h4 style={{paddingTop: "20px"}}>Kalkylator</h4>
                <WinterstorageCalc calcPrices={calcPrices} selectedUserForm={selectedUserForm}/>
              </div>
            </div>
            
            <div className="WinterStorageMakeOffer"> 
              <h4>Offert</h4>
              <div>
                Pris: 
                <input 
                  className="WinterStorageMakeOfferPrice"
                  type="text" 
                  value={selectedUserForm.price} 
                  onChange={(e)=> setSelectedUserForm(prevState => ({...prevState, price: e.target.value}))}/>
              </div>
              <div>
                Kommentarfält mellan försäljare och kund:
                <textarea  
                  className="WinterStorageMakeOfferCommentCustomer"
                  spellCheck="false"
                  value={formatFieldValue(selectedUserForm.comment_to_customer)||''}
                  onChange={(e)=> setSelectedUserForm(prevState => ({...prevState, comment_to_customer: e.target.value}))}/>
              </div>
              <div>
                Kommentarfält mellan försäljare: (syns inte till kunden)
                <textarea  
                  className="WinterStorageMakeOfferComment"
                  spellCheck="false"
                  value={formatFieldValue(selectedUserForm.comment_to_others)||''}
                  onChange={(e)=> setSelectedUserForm(prevState => ({...prevState, comment_to_others: e.target.value}))}/>
              </div>
            </div>
            <div className="WinterStorageButtons">
              <form onSubmit={saveChanges}>
                <input type="submit" value="SPARA ÄNDRINGAR" className="PasswordSaveButton WinterStorageSaveButton"/>
              </form>
              <form onSubmit={saveAndSend}>
                <input type="submit" value="SKICKA OFFERT" className="PasswordSaveButton WinterStorageSaveButton"/>
              </form>
              <form onSubmit={deleteRequestForm}>
                <input type="submit" value="TA BORT" className="PasswordSaveButton WinterStorageSaveButton"/>
              </form>
              <form onSubmit={(e)=> {
                e.preventDefault()
                if (selectedUserForm.customer_accepted_offer===0) {
                  setMessage({type:"warning", text: "OBS! Funktionen inte ännu implementerad! :P Ingenting händer", onOK:()=>{
                    setMessage(false) // \n\rKunden har ej godkänt offerten!\n\rVill du ändå göra försäljningen?
                  }, onNO: ()=> {
                    setMessage(false)
                  }})
                } else {
                  setMessage({type:"warning", text: "OBS! Funktionen inte ännu implementerad! :P Ingenting händer", onOK:()=>{
                    setMessage(false)// \n\rOm du godkänner försäljning så bildas alla överenskomna arbetsmoment.\n\rÄr du säker?
                  }, onNO: ()=> {
                    setMessage(false)
                }})}}}>
                <input type="submit" value="GODKÄNN FÖRSÄLJNING" className="PasswordSaveButton WinterStorageSaveButton"/>
              </form>
            </div>
          </div>
        </ComponentContainer>}
        </>
  )
}

export default WinterstorageRequests