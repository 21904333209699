import React, { useState} from 'react'
import ArrowUp from '../Assets/arrow_up.svg'
import ArrowDown from '../Assets/arrow_down.svg'
import FormClose from '../Assets/form_close.svg'
 
import './ComponentContainer.css'

function ComponentContainer(props) {
  const {header, onMinimize, onClose, maxwidth, disablebuttons} = props
  const [show, setShow]= useState(true);
  const [close, setClose] = useState(false)


  if (!close) {return (
    <div className="ComponentsContainer" style={maxwidth&&{maxWidth:maxwidth}}>
      <div className="ComponentsContainerHeader">
        <div className="ComponentsContainerHeaderContent">
          {header}
        </div>
        <div className="ComponentsContainerHeaderTools">
          {!disablebuttons&&<><img src={show?ArrowUp:ArrowDown} height={20} onClick={()=>onMinimize?onMinimize():setShow(!show)}/>
          <img src={FormClose} height={20} onClick={()=> onClose?onClose():setClose(!close)}/></>}
        </div>
      </div>
      <div className="ComponentsContainerContent" style={{maxHeight: show?"100%":"0px", paddingTop: show?"25px":"0px", paddingBottom: show?"25px":"0px", overflow:show?'visible':'hidden'}}>
        {props.children}
      </div>
    </div>
  )} else return(<></>)
}

export default ComponentContainer