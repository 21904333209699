import React, {useEffect} from 'react'
import './Notification.css'

function Notification(props) {
  useEffect(()=> {
    return(()=> {
      props.onOK()
    })
  }, [])
  return (
    <div className="ComponentsContainer">
      <div className="NotificationContainer">
        <p>{props.text}</p>
        <input type="submit" value="OK" className="PasswordSaveButton" onClick={props.onOK}/>
      </div>
    </div>
  )
}

export default Notification