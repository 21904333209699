import React, { useEffect, useState } from 'react';
import './Unsubscribe.css';

function Unsubscribe({pathid, pathkey, setMessage}) {

  async function doit() {
    try {
      let res
      if (window.location.search.split('?userid=')) {
        const userid= window.location.search.split('?userid=')[1].split('&')[0]
        const userkey= window.location.search.split('&key=')[1]
        res= await fetch('https://app.venekeskus.com/api/customer/unsubscribe?userid='+ userid+ '&key='+ userkey, {
          method: 'GET',
          mode: 'cors',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          } 
        })

      } else {
        res= await fetch('https://app.venekeskus.com/api/customer/unsubscribe?id='+ pathid+ '&key='+ pathkey, {
          method: 'GET',
          mode: 'cors',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          } 
        })
      }
      let data = await res.json();
      if (res.status===200) {
        setMessage({text: "Unsubscribed!", type: "success", onOK: () =>{
          //window.location.replace(window.location.origin)
        }})
      } else {
        throw Error
      }
    } catch (e) {
      setMessage({text: "Already Unsubscribed", type: "success", onOK: () =>{
        //window.location.replace(window.location.origin)
      }})
    }
  }

  useEffect(()=> {
    doit()
  }, [])

  return (
    <div className="UnsubscribeContainer">
      <div className="Unsubscribe">
      </div>
    </div>
  )
}

export default Unsubscribe