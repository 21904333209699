import React, { useEffect, useState, useContext } from 'react'
import { Switch, Route, useHistory, useLocation } from "react-router-dom"

import { DimensionContext } from './WithDimensions'
import Account from './Account'
import Admin from './Admin'
import WinterstorageRequests from './WinterstorageRequests'
import Nav from './Nav'
import Header from './Header'
import Footer from './Footer'
import Customers from './Customers'
import Sales from './Sales'

import './MainApp.css'

function MainApp(props) {
  const {screenHeight, screenWidth}= useContext(DimensionContext)
  const {user, logout, socket, response, setResponse, setSilentMSG, setMessage}= props // {user, logout, socket, response, setResponse}
  const history       = useHistory()
  const location      = useLocation()
  const [notifications, setNotifications] = useState({})

  useEffect(() => { // only update notifications if they are old
    if (!localStorage.getItem('notifications')||localStorage.getItem('notificationsTimeStamp')< (Date.now()- 3600000* 2)) { // update every 2h or call
      socket.send(JSON.stringify({
        "action": "winterstorage_request_forms_notifications",
      }))
    } else {
      if (localStorage.getItem('notifications')) setNotifications(JSON.parse(localStorage.getItem('notifications')))
    }
  }, [])

  useEffect(()=> {
    if (user.privileges=== 0&& user.firstlogin=== true) setMessage({
      text: user.lang==='fi'?
        "Hyvä asiakas, Båthuset-sovelluksemme on parhaimmillaan kehityksen alla!\n Pian voit katsoa tarjouksesi täältä!":
        "Bästa kund, Båthuset-applikationen är som bäst under konstruktion!\nInnom snar framtid kan du se dina offerter här!", onOK: ()=> {
        setMessage({text: ""})}})
  }, [user])

  useEffect(() => {
    if (user?.firstlogin=== true) {
      history.replace('/account/changepassword')
    } if (location.pathname==='/') history.replace('/winterstoragerequests')
  }, [history, user])
  
  useEffect(() => {
    if (response?.winterstorage_request_forms_notifications) {
      setNotifications(prevState => ({
        ...prevState, 
        winterstorage: response.winterstorage_request_forms_notifications.winterstorage["COUNT(_id)"],
        sales: response.winterstorage_request_forms_notifications.sales["COUNT(_id)"]
      }))
      localStorage.setItem('notifications', JSON.stringify({
        winterstorage: response.winterstorage_request_forms_notifications.winterstorage["COUNT(_id)"],
        sales: response.winterstorage_request_forms_notifications.sales["COUNT(_id)"]
      }))
      localStorage.setItem('notificationsTimeStamp', Date.now())
    }
  }, [response])
  // setSilentMSG({text:"testar", type:"warning", onOK:()=>{setSilentMSG(false)}})
  return (
    <div className="MainApp">
      <Nav privileges={user.privileges} {...props} screenHeight={screenHeight} screenWidth={screenWidth} notifications={notifications}/>
      <div className="MainAppContainer">
        <Header {...props}/>
        <Switch>
          <Route exact path="/"> 
          </Route>
          {user.privileges>=5&&
            <Route path="/admin">
              <Admin {...props}/>
            </Route>}
          <Route path="/account">
            <Account {...props}/>
          </Route>
          {user.privileges>=3&&
            <Route path="/winterstoragerequests">
              <WinterstorageRequests {...props}/>
            </Route>}
          {user.privileges>=3&&
          <Route path="/customers">
            <Customers {...props} screenHeight={screenHeight} screenWidth={screenWidth}/>
          </Route>}
          {user.privileges>=3&&
          <Route path="/sales">
            <Sales {...props} screenHeight={screenHeight} screenWidth={screenWidth}/>
          </Route>}
        </Switch>
        <Footer/>
      </div>
    </div>
  )
}

export default MainApp