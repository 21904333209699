import React, {useEffect, useState} from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import SearchIcon from '../Assets/search_icon.svg'
import './Header.css'

function Header(props) {
  const {user, search, setSearch } = props
  const [searchInput, setSearchInput] = useState('')
  const [openVer, setOpenVer] = useState(false)
  const history = useHistory()
  const location = useLocation()
  const navItems = [{
    text: user.lang==='fi'?'Talvisäilytys':'Vinterförvaring',
    path: '/winterstoragerequests',
  }, {
    text: user.lang==='fi'?'Myynti':'Försäljning',
    path: '/sales',
  }, {
    text: user.lang==='fi'?'Asiakkat':'Kunder',
    path: '/customers',
  },{
    text: user.lang==='fi'?'Työmäärät':'Arbetsuppgifter',
    path: '/tasks'
  },{
    text: user.lang==='fi'?'Kalenteri':'Kalender',
    path: '/calendar'
  },{
    text: 'Admin',
    path: '/admin',
  }, {
    text: user.lang==='fi'?'Veneitä':'Båtar',
    path: '/boats',
  }, {
    text: user.lang==='fi'?'Kuvia':'Bilder',
    path: '/images',
  }, {
    text: user.lang==='fi'?'Laskut':'Fakturor',
    path: '/bills',
  }, {
    text: user.lang==='fi'?'Tili':'Konto',
    path: '/account',
  }]

  function privs(p) {
    if (p===5) {return "admin"} else
    if (p===4) {return "faktura"} else
    if (p===3) {return "försäljare"} else
    if (p===2) {return "service"} else
    if (p===1) {return "micro"}
    if (p===0) {return "kund"} else 
      return null
  }

  function runSearch(){
    setSearch(searchInput)
  }

  useEffect(()=> { // remove search if user changes location, only if something is in the field
    if (searchInput===''&&search!=='') setSearch('')
  }, [searchInput])
  
  useEffect(()=>{
    setSearch('')
    setSearchInput('')
  }, [location.pathname])

  return (
    <div className="Header">
      <div className="HeaderUpperContainer">
        <div className="HeaderUpperLeft">
          <div className="HeaderHeader">{navItems.map(item => {if (location.pathname.includes(item.path)) return item.text})}</div>
        </div>
        <div className="HeaderUppperRight">
          <div>
            {privs(user.privileges)}
          </div>
          <div style={
            user.privileges>0?{cursor: "pointer", fontSize:"8px", alignSelf: "flex-end", justifySelf: "flex-end", color: "#000"}
            :
            {fontSize:"8px", alignSelf: "flex-end", justifySelf: "flex-end", color: "#000"}} onClick={user.privileges>0?()=>{setOpenVer(!openVer)}:null}>
            {"\n\r ver 27.8.21"/* ************************************ VERSION ************************************ */}
          </div>
        </div>
      </div>
      <div className="HeaderLowerContainer">
        <div>
          
        </div>
        <div className="HeaderSearchBar">
          <input id="searchField" type="text" value={searchInput} onKeyDown={(e)=> {if (e.key==='Enter') runSearch()}} onChange={(e)=> setSearchInput(e.target.value)} spellCheck={false}/>
          <img src={SearchIcon} onClick={()=> document.getElementById("searchField").focus()}/>
          <span onClick={()=> {runSearch(); document.getElementById("searchField").focus()}}>Sök</span>
        </div>
      </div>
      {openVer&&
        <div style={{backgroundColor: "#ccc", position: "fixed", left: "50%", top: "50%", zIndex: "3", transform: "translate(-50%, -50%)", boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}>
          <div style={{display: "flex", justifyContent:"flex-end", padding: "5px"}}><div style={{cursor: "pointer"}} onClick={()=>{setOpenVer(!openVer)}}>[x]</div></div>
          <div style={{padding: "10px", width: "300px", whiteSpace:"pre-line", textAlign: "left", backgroundColor: "#fff", maxHeight: "400px", overflowY:"scroll"}}>
            {"Version 27.8.21\n"}
            {"- Stor del av tabellerna har nu manuell sortering om man klickar på rubriken, sortera efter status kommer tyvärr i ett senare skede, för tillfället är statustexten beroende på flera värden och måste implementeras skilt\n"}
            {"- PDF har nu pris, samt andra formatförbättringar\n"}
            {"- Offerternas datum uppdaters fr.o.m nu efter varje uppdatering av objektet\n"}
            {"- CSS förbättringar\n"}
            {"- Offerten ber nu kunden att fylla i adressuppgifter\n"}
            {"- Bugfix -> tabeller updaterade inte totala antalet objekt, så sidorna blev mindre än vad det fanns objekt\n"}
            {"- Bugfix -> Kalkylatorn formaterade till fel hästkrafter ifall det fanns kommatecken\n"}
            {"- Mera små bugfixar...\n"}
            {"\n"}
            {"Version 26.8.21\n"}
            {"- Bugfix -> Sidan blev och ladda när man öppna en användare\n"}
            {"- Databaskärnan färdig för försäljningen\n"}
            {"\n"}
            {"Version 25.8.21\n"}
            {"- Ikonen uppe i hörnet uppdaterad\n"}
            {"- Kalender borttagen från formuläret\n"}
            {"- Bugfix -> Kalkylatorn fungerade inte utan motorservice\n"}
            {"- Bugfix -> Verktyget visade inte alltid om motorn var innombordare\n"}
            {"- Tillsatt \"Offert av\" till försäljningstabellen\n"}
            {"- Nytt format på statistiken i offertöversikten\n"}
            {"- Status har nu färg i offertöversikten\n"}
            {"- Alla nya offertförfrågningar har nu blankt uppt./sjös. datum\n"}
            {"- Tillsatt knapp på formuläret -> priser\n"}
            {"- Tillsatt text på offerten var det uppmanas ta kontakt med försäljare angående uppt./sjös. datum och tid ifall det inte är bestämt ännu. Det går fortfarande att färdigt skriva in i verktyget ifall den redan är bestämd\n"}
            {"\n"}
            {"Version 18.8.21\n"}
            {"- Kalkylator skapad till offertverktygen\n"}
            {"\n"}
            {"Version 17.8.21\n"}
            {"- Offertöversikten har nu \"Offert av\" fält\n"}
            {"- Endast icke accepterade offerter i offertöversikt\n"}
            {"- Användare kan nu ändra sina kontouppgifter under Konto/Uppgifter samt användaren med rättigheter 2(service) eller uppåt kan ladda upp en profilbild, som automatiskt kommer med på offerten under \"Försäljare\"\n"}
            {"\n"}
            {"Version 12.8.21\n"}
            {"- Förkortat PDF dokumentet\n"}
            {"- Server hårddisk förstorad\n"}
            {"- Lagt till \"Jag har läst och godkänt förvaringsvillkoren\" till offertsidan för kunden\n"}
            {"- Offertsidan för kunden mobilresponsiv\n"}
            {"- Bugfix -> PDF visade 'undefined' om användaren inte hade språkinställning definierad\n"}
            {"- Bugfix -> PDF error om kommentarfält var tomt\n"}
            {"- Rättat villkoren\n"}
            {"- Mera översättningar gjorda för kundkonto\n"}
            {"\n"}
            {"Version 11.8.21\n"}
            {"- Öppna offert i PDF (under försäljning)\n"}
            {"- Möjligt att göra rapport under Admin\n"}
            {"- Upgradering av server programvara (nodeJS, reactJS, npm, pm2)\n"}
            {"- Översättningar på kundkonto\n"}
            {"- Bugfix -> lägg till användare utan KundID ledde till databas error\n"}
            {"- Server omstart 23.00 ->\n"}
            {"\nTODO\n"}
            {"- Varna om någon redan redigerar ett objekt\n"}
            {"- Lägg till möjligheter för kundkonton, bl a se öppna och godkända offerter\n"}
            {"- Kundkonto automatiskt registrera sin KundID# skillt från AnvändarID#\n"}
            {"- Lägg till prisalternativ för kalkylatorn under Admin\n"}
            {"- Lägg till \"Lägg till försäljning\"\n"}
            {"- Lägg till Bilder funktion\n"}
            {"- Lägg till Arbeten funktion\n"}
            {"- Lägg till Kalender översikt\n"}
            {"- Göra av med RedisDB(onödig)\n"}
            {"- Bugfix, låg, kalkylatorn har problem med första renderingen ibland\n"}
            {"- Bugfix, mellan, lägg till användare epost regex test/format\n"}
            {"- Bugfix, låg, om sidan har exakt 15 objekt i Försäljning, tror den det finns en sida till\n"}
            {"- Bugfix, låg, Offertöversikt har mer sidor än vad det finns objekt\n"}
            {"- Göra objekt i offertverktygets kalendern tryckbart-> leda till offerten\n"}
            {"- osv osv ...\n"}
          </div>
        </div>}
    </div>
  )
}

export default Header